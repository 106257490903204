import { useCallback, useEffect, useRef, useState } from 'react';
import { EditorContent } from '@tiptap/react';
import { useEditor } from './EditorContext';
import { formatDate } from '../../utils/dateUtils';
import NEW_JournalApiService from '../../services/NEW_JournalApiService';
import { useAuth } from '../../contexts/AuthContext';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import "../../assets/css/TiptapMini.css";


const TiptapContent = () => {
    const [showPngConverter, setShowPngConverter] = useState(false);
    const [pngFile, setPngFile] = useState(null);
    const { user } = useAuth();
    const editor = useEditor();

    // Keeps track of recently added images to prevent immediate deletion
    const recentlyAddedImages = useRef(new Set());

    // Maps image keys to filenames for deletion purposes
    const imageKeyToFilename = useRef(new Map());

    const getImageDimensions = (file) => {
        return new Promise((resolve) => {
            const img = new Image();
            img.onload = () => {
                resolve({ width: img.naturalWidth, height: img.naturalHeight });
            };
            img.src = URL.createObjectURL(file);
        });
    };

    const handleDrop = async (event) => {
        event.preventDefault();

        const files = Array.from(event.dataTransfer.files).filter((file) =>
            file.type.startsWith('image/')
        );

        if (!editor || !user || !user.username) {
            console.error('Editor is not initialized or user is not authenticated');
            toast.error('Editor is not initialized or you are not authenticated.', {
                autoClose: false,
                closeOnClick: true
            });
            return;
        }

        for (const file of files) {
            // Check if the file is a JPG or PNG
            if (file.type !== 'image/jpeg' && file.type !== 'image/jpg' && file.type !== 'image/png') {
                toast.error(`${file.name} is not a JPG or PNG file. Only JPG and PNG files are allowed.`, {
                    autoClose: false,
                    closeOnClick: true
                });
                continue;
            }

            if (file.type === 'image/png') {
                const shouldConvert = window.confirm(`${file.name} is a PNG file. Would you like to convert it to JPG before uploading? Only JPG format allowed.`);

                if (shouldConvert) {
                    try {
                        const jpgFile = await convertPngToJpg(file);
                        await processAndUploadImage(jpgFile);
                        toast.success(`${file.name} converted to JPG and uploaded successfully`, {
                            autoClose: 2000,
                            closeOnClick: true
                        });
                    } catch (error) {
                        console.error('Conversion error:', error);
                        toast.error(`Failed to convert ${file.name} to JPG: ${error.message}`, {
                            autoClose: false,
                            closeOnClick: true
                        });
                    }
                } else {
                    // User chose not to convert, ask if they want to upload the PNG as-is
                    const shouldUploadPng = window.confirm(`Do you want to upload ${file.name} as a PNG file?`);
                    if (shouldUploadPng) {
                        await processAndUploadImage(file);
                    } else {
                        toast.info(`Upload of ${file.name} cancelled`, {
                            autoClose: 1000,
                            closeOnClick: true
                        });
                    }
                }
            } else {
                // JPG file, process normally
                await processAndUploadImage(file);
            }
        }
    };

    const convertPngToJpg = (file) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => {
                const canvas = document.createElement('canvas');
                canvas.width = img.width;
                canvas.height = img.height;
                const ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0);
                canvas.toBlob((blob) => {
                    if (blob) {
                        const jpgFile = new File([blob], file.name.replace('.png', '.jpg'), {
                            type: 'image/jpeg',
                            lastModified: new Date().getTime()
                        });
                        resolve(jpgFile);
                    } else {
                        reject(new Error('Conversion failed'));
                    }
                }, 'image/jpeg', 0.9);
            };
            img.onerror = () => reject(new Error('Failed to load image'));
            img.src = URL.createObjectURL(file);
        });
    };

    const processAndUploadImage = async (file) => {
        let tempUrl;
        const imageId = uuidv4(); // Unique ID for the image node

        try {

            // const uploadingToastId = toast.info(`Uploading ${file.name}`, {
            //     autoClose: 1000,
            //     closeOnClick: true
            // });

            // Get image dimensions
            const { width, height } = await getImageDimensions(file);

            // Create a temporary URL for the image
            tempUrl = URL.createObjectURL(file);

            const date = formatDate(new Date());
            const username = user.username;

            // Insert a placeholder image into the editor
            editor.commands.focus();
            editor
                .chain()
                .setImage({
                    src: tempUrl,
                    'data-filename': file.name,
                    'data-id': imageId,
                    'data-username': username,
                    'data-date': date,
                    width,
                    height,
                    'data-just-uploaded': 'true',
                })
                .run();

            // Upload the image to the server
            const uploadResponse = await NEW_JournalApiService.uploadImage(
                username,
                date,
                file
            );
            const imageKey = uploadResponse.key;

            // Find the image node by its unique ID and update it with the permanent URL
            const { doc } = editor.state;
            let imageNodePos = null;
            doc.descendants((node, pos) => {
                if (
                    node.type.name === 'image' &&
                    node.attrs['data-id'] === imageId
                ) {
                    imageNodePos = pos;
                    return false; // Stop searching
                }
                return true;
            });

            if (imageNodePos !== null) {
                const node = doc.nodeAt(imageNodePos);
                if (node) {
                    const transaction = editor.state.tr.setNodeMarkup(
                        imageNodePos,
                        undefined,
                        {
                            ...node.attrs,
                            src: imageKey,
                            'data-filename': file.name,
                            'data-id': imageId,
                            'data-username': username,
                            'data-date': date,
                            width,
                            height,
                            'data-just-uploaded': 'true',
                        }
                    );
                    editor.view.dispatch(transaction);

                    // Track the image to prevent immediate deletion
                    recentlyAddedImages.current.add(imageKey);
                    setTimeout(
                        () => recentlyAddedImages.current.delete(imageKey),
                        5000
                    );

                    // Map the image key to the filename for deletion purposes
                    imageKeyToFilename.current.set(imageKey, file.name);
                }
            } else {
                console.error('Image node not found or has changed');
            }

            // Revoke the temporary URL to free up resources
            URL.revokeObjectURL(tempUrl);

            // Update the upload notification
            // toast.update(uploadingToastId, {
            //     autoClose: 1000,
            //     closeOnClick: true
            // });
        } catch (error) {
            console.error('Error handling image:', error);

            // Remove the placeholder image if the upload failed
            const { doc } = editor.state;
            let imageNodePos = null;
            doc.descendants((node, pos) => {
                if (
                    node.type.name === 'image' &&
                    node.attrs['data-id'] === imageId
                ) {
                    imageNodePos = pos;
                    return false;
                }
                return true;
            });

            if (imageNodePos !== null) {
                const node = doc.nodeAt(imageNodePos);
                if (node) {
                    const transaction = editor.state.tr.delete(
                        imageNodePos,
                        imageNodePos + node.nodeSize
                    );
                    editor.view.dispatch(transaction);
                }
            }

            // Revoke the temporary URL
            if (tempUrl) {
                URL.revokeObjectURL(tempUrl);
            }

            // Notify the user of the failure
            toast.error(`Image upload failed: ${error.message}`, {
                autoClose: false,
                closeOnClick: true
            });
        }
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const handleImageDeletion = useCallback(
        async ({ transaction }) => {
            if (!user) return;

            // Check if the transaction replaces the entire document
            const isFullDocReplacement = transaction.steps.some((step) => {
                return (
                    step.from === 0 &&
                    step.to >= transaction.before.content.size &&
                    step.slice.content.size >= transaction.before.content.size
                );
            });

            if (isFullDocReplacement) {
                // For full document replacements, don't delete any images
                // console.log('Full document replacement detected, skipping image deletion');
                return;
            }

            const oldDoc = transaction.before;
            const newDoc = transaction.doc;

            const oldImages = [];
            const newImages = [];

            // Collect image keys and attributes from the old document
            oldDoc.descendants((node) => {
                if (node.type.name === 'image') {
                    const imageKey = node.attrs.src;
                    if (imageKey && !imageKey.startsWith('blob:')) {
                        oldImages.push({ imageKey, attrs: node.attrs });
                    }
                }
            });

            // Collect image keys from the new document
            newDoc.descendants((node) => {
                if (node.type.name === 'image') {
                    const imageKey = node.attrs.src;
                    if (imageKey && !imageKey.startsWith('blob:')) {
                        newImages.push(imageKey);
                    }
                }
            });

            // Determine which images have been deleted
            const deletedImages = oldImages.filter(
                ({ imageKey }) =>
                    !newImages.includes(imageKey) &&
                    !recentlyAddedImages.current.has(imageKey)
            );

            // Delete images from the server
            for (const { imageKey, attrs } of deletedImages) {
                try {
                    let filename = imageKeyToFilename.current.get(imageKey);

                    if (!filename) {
                        filename = attrs['data-filename'] || imageKey.split('/').pop();
                    }

                    // Extract username and date from attributes or imageKey
                    let username = attrs['data-username'] || user.username;
                    let date = attrs['data-date'];

                    if (!date) {
                        // Try to parse date from imageKey
                        const segments = imageKey.split('/');
                        const dateIndex = segments.indexOf(username) + 1;
                        date = segments[dateIndex];
                    }

                    if (!username || !date || !filename) {
                        console.error('Missing necessary data for image deletion');
                        continue;
                    }

                    await NEW_JournalApiService.deleteImage(username, date, filename);

                    // Remove the mapping
                    imageKeyToFilename.current.delete(imageKey);

                    // toast.success(`Deleted image: ${filename}`, {
                    //     autoClose: 500,
                    //     closeOnClick: true
                    // });
                } catch (error) {
                    console.error('Error deleting image:', error);
                    toast.error(
                        `Failed to delete image from storage. It may need to be removed manually.`
                    );
                }
            }
        },
        [user]
    );

    useEffect(() => {
        if (editor) {
            editor.on('transaction', handleImageDeletion);
        }
        return () => {
            if (editor) {
                editor.off('transaction', handleImageDeletion);
                // Clear the recently added images set and image key to filename map
                recentlyAddedImages.current.clear();
                imageKeyToFilename.current.clear();
            }
        };
    }, [editor, handleImageDeletion]);

    if (!editor) {
        return null;
    }

    return (
        <div onDrop={handleDrop} onDragOver={handleDragOver}>
            <EditorContent
                editor={editor}
                className="tiptap-editable-content"
                spellCheck="true"
            />
        </div>
    );

};

export default TiptapContent;