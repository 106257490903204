// import React from "react";
// import Navbar from "../components/Navbar";
import Calendar from "../components/calendar/Calendar";
import { useTheme } from "../components/ThemeProvider";

const Journal = () => {
  const { theme } = useTheme();

  return (
    <div>
      {/* <Navbar /> */}
      <Calendar />
    </div>
  );
};

export default Journal;
