import { useState, useEffect } from 'react';

const SaveSection = ({
    goToToday,
    handleManualSave,
    isLoading,
    hasUnsavedChanges,
    saveError
}) => {
    const [showSaveButton, setShowSaveButton] = useState(false);

    useEffect(() => {
        let timer;
        if (hasUnsavedChanges || saveError) {
            setShowSaveButton(false);
            timer = setTimeout(() => {
                setShowSaveButton(true);
            }, 0);
        } else {
            setShowSaveButton(false);
        }

        return () => clearTimeout(timer);
    }, [hasUnsavedChanges, saveError]);

    if ((hasUnsavedChanges || saveError) && showSaveButton) {
        return (
            <button
                className={`btn btn-sm ${saveError ? 'btn-danger' : 'btn-outline-warning'}`}
                onClick={handleManualSave}
                disabled={isLoading}
            >
                {saveError ? 'Retry Save' : 'Save Now'}
            </button>
        );
    }

    return (
        <button
            className="btn btn-sm btn-outline-primary"
            onClick={goToToday}
        >
            Today
        </button>
    );
};

export default SaveSection;