import { useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { formatDate } from "../../utils/dateUtils";
import { safeJsonParse } from "../../utils/functionUtils";

const SaveHandler = ({
    apiService,
    selectedDate,
    unsavedEntries,
    currentContent,
    setEntries,
    setUnsavedEntries,
    setCurrentContent,
    setError,
    setIsLoading,
    setSaveError
}) => {
    const { user } = useAuth();

    const preprocessContent = async (content) => {
        const processNode = async (node) => {
            if (node.type === 'image' && (!node.attrs.width || !node.attrs.height)) {
                return new Promise((resolve) => {
                    const img = new Image();
                    img.onload = () => {
                        node.attrs.width = img.naturalWidth;
                        node.attrs.height = img.naturalHeight;
                        resolve(node);
                    };
                    img.src = node.attrs.src;
                });
            }
            if (node.content) {
                node.content = await Promise.all(node.content.map(processNode));
            }
            return node;
        };

        return processNode(content);
    };

    const handleSave = async (contentToSave = null) => {
        if (!apiService) {
            console.log("API service not yet loaded");
            setSaveError("API service not available");  // Set save error
            return false;
        }

        setError(null);
        setSaveError(null);  // Reset save error before attempting to save

        if (selectedDate && user && user.username) {
            const dateKey = formatDate(selectedDate);
            const content = contentToSave || unsavedEntries[dateKey] || currentContent;
            setIsLoading(true);
            try {
                const processedContent = await preprocessContent(content);
                const contentString = JSON.stringify(processedContent);

                const response = await apiService.addEntry(
                    user.username,
                    dateKey,
                    contentString
                );

                setEntries((prevEntries) => ({
                    ...prevEntries,
                    [dateKey]: content,
                }));

                setUnsavedEntries((prevUnsaved) => {
                    const updatedUnsaved = { ...prevUnsaved };
                    delete updatedUnsaved[dateKey];
                    return updatedUnsaved;
                });

                setCurrentContent(content);
                return true; // Indicate successful save
            } catch (err) {
                console.error("Failed to save entry:", err);
                setError("An error occurred while saving. Please try again.");
                setSaveError("Failed to save. Please try again.");  // Set save error
                return false; // Indicate failed save
            } finally {
                setIsLoading(false);
            }
        } else {
            setSaveError("Unable to save: missing date or user information");  // Set save error
        }
        return false; // Indicate no save attempt made
    };

    return { handleSave };
};

export default SaveHandler;