// constants.js

// export const DAYS_OF_WEEK = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
export const DAYS_OF_WEEK = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

export const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const INITIAL_ENTRY_STATE = {
  application: "",
  user: "",
  password: "",
  url: "",
  category: "",
};

export const INITIAL_PASSWORD_STRENGTH = {
  score: 0,
  label: "None",
  variant: "danger",
};

export const PASSWORD_STRENGTH_LEVELS = {
  VERY_WEAK: { score: 20, label: "Very Weak", variant: "danger" },
  WEAK: { score: 40, label: "Weak", variant: "warning" },
  MEDIUM: { score: 60, label: "Medium", variant: "info" },
  STRONG: { score: 80, label: "Strong", variant: "primary" },
  VERY_STRONG: { score: 100, label: "Very Strong", variant: "success" },
};

export const MINIMUM_PASSWORD_STRENGTH = 60;

// Add any other constants you might need across your application
