// import React from 'react';
import { Modal, Button, Spinner } from 'react-bootstrap';

const UndoneTasksModal = ({ show, onHide, onMove, isScanning, currentRange, dateRange }) => {
    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton={!isScanning}>
                <Modal.Title>
                    {isScanning ? 'Scanning for Undone Tasks' : 'Undone Tasks Found'}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {isScanning ? (
                    <div className="text-center p-3">
                        <Spinner animation="border" role="status" className="mb-2" />
                        <p>Scanning {currentRange} for undone tasks...</p>
                    </div>
                ) : (
                    dateRange && (
                        <>
                            <p>Would you like to move undone tasks from previous entries to today?</p>
                            <p>Tasks found in entries from {dateRange.start} to {dateRange.end}</p>
                        </>
                    )
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide} disabled={isScanning}>
                    Cancel
                </Button>
                {!isScanning && (
                    <Button variant="primary" onClick={onMove}>
                        Move Tasks
                    </Button>
                )}
            </Modal.Footer>
        </Modal>
    );
};

export default UndoneTasksModal;