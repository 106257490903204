// PaperComponent.jsx
import React, { useEffect, useRef, useState } from 'react'
import { NodeViewWrapper } from '@tiptap/react'
import * as d3 from 'd3'
import { v4 as uuid } from 'uuid'
import { Form } from 'react-bootstrap'

const getRandomElement = (list) => {
    return list[Math.floor(Math.random() * list.length)]
}

const COLORS = ['#A975FF', '#FB5151', '#FD9170', '#FFCB6B', '#68CEF8', '#80CBC4', '#9DEF8F']

const PaperComponent = ({ node, updateAttributes }) => {
    const [color, setColor] = useState(getRandomElement(COLORS))
    const [size, setSize] = useState(Math.ceil(Math.random() * Math.floor(10)))
    const [drawing, setDrawing] = useState(false)
    const [id, setId] = useState(uuid())
    const canvasRef = useRef(null)
    const pointsRef = useRef([])
    const pathRef = useRef(null)

    useEffect(() => {
        const svgElement = d3.select(canvasRef.current)

        const handleStartDrawing = (event) => {
            event.preventDefault()
            setDrawing(true)
            pointsRef.current = []

            // Remove any existing temporary path
            svgElement.select(`#temp-path`).remove()

            // Create a new temporary path for the current drawing
            pathRef.current = svgElement
                .append('path')
                .attr('id', 'temp-path')
                .attr('stroke', color)
                .attr('stroke-width', size)
                .attr('fill', 'none')
                .attr('stroke-linecap', 'round')
                .attr('stroke-linejoin', 'round')

            // Get initial point
            const point = d3.pointers(event, canvasRef.current)[0]
            pointsRef.current.push(point)
        }

        const handleMove = (event) => {
            if (!drawing) return
            event.preventDefault()

            // Add new point
            const point = d3.pointers(event, canvasRef.current)[0]
            pointsRef.current.push(point)

            // Update the temporary path
            const line = d3.line().curve(d3.curveBasis)
            pathRef.current.attr('d', line(pointsRef.current))
        }

        const handleEndDrawing = (event) => {
            if (!drawing) return
            event.preventDefault()

            setDrawing(false)

            // Remove temporary path
            svgElement.select('#temp-path').remove()

            if (pointsRef.current.length < 2) return

            // Create the final path data
            const line = d3.line().curve(d3.curveBasis)
            const pathData = line(pointsRef.current)

            // Add the new line to the stored lines
            const newLine = {
                id: id,
                color: color,
                size: size,
                path: pathData
            }

            const currentLines = node.attrs.lines || []
            updateAttributes({
                lines: [...currentLines, newLine]
            })

            // Generate new ID for next line
            setId(uuid())
            pointsRef.current = []
        }

        svgElement
            .on('mousedown touchstart', handleStartDrawing)
            .on('mousemove touchmove', handleMove)
            .on('mouseup touchend mouseleave touchleave', handleEndDrawing)

        return () => {
            svgElement
                .on('mousedown touchstart', null)
                .on('mousemove touchmove', null)
                .on('mouseup touchend mouseleave touchleave', null)
        }
    }, [color, size, drawing, id, node.attrs.lines, updateAttributes])

    const clear = () => {
        updateAttributes({
            lines: []
        })
    }

    return (
        <NodeViewWrapper className="draw">
            <div className="control-group">
                <div className="button-group d-flex gap-2 mb-2">
                    <Form.Control
                        type="color"
                        value={color}
                        onChange={(e) => setColor(e.target.value)}
                    />
                    <Form.Control
                        type="number"
                        min="1"
                        max="10"
                        value={size}
                        onChange={(e) => setSize(Number(e.target.value))}
                        style={{ width: '80px' }}
                    />
                    <button className="btn btn-secondary" onClick={clear}>
                        Clear
                    </button>
                </div>
                <svg
                    viewBox="0 0 500 250"
                    ref={canvasRef}
                    style={{ background: '#f1f3f5', cursor: 'crosshair' }}
                >
                    {node.attrs.lines.map((item) => (
                        <path
                            key={item.id}
                            d={item.path}
                            stroke={item.color}
                            strokeWidth={item.size}
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    ))}
                </svg>
            </div>
        </NodeViewWrapper>
    )
}

export default PaperComponent