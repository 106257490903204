// TiptapEditor.jsx
import { useState, useEffect, useCallback, useRef } from 'react';
import { EditorProvider } from './EditorContext';
import EditorToolbar from './Toolbar/EditorToolbar';
import EditorContent from './EditorContent';
import { useTiptapConfig } from './useTiptapConfig';
import { useTheme } from '../ThemeProvider';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleUp, faAngleDoubleDown, faThumbtack } from "@fortawesome/free-solid-svg-icons";

const TiptapEditor = ({
    initialContent,
    onUpdate,
    onSave,
    selectedDate,
    lastSavedContent,
    hasUnsavedChanges,
    setHasUnsavedChanges
}) => {
    const { theme } = useTheme();
    const [editorKey, setEditorKey] = useState(selectedDate.toISOString());
    const prevDateRef = useRef(selectedDate);
    const editorRef = useRef(null);
    const isUpdatingRef = useRef(false);
    const [touchStartY, setTouchStartY] = useState(null);
    const [isTouchDevice, setIsTouchDevice] = useState(false);
    const [isEditorReady, setIsEditorReady] = useState(false);
    const autoSaveTimeoutRef = useRef(null);
    const lastSavedContentRef = useRef(lastSavedContent);

    const isContentChanged = useCallback((newContent, oldContent) => {
        const result = JSON.stringify(newContent) !== JSON.stringify(oldContent);
        return result;
    }, []);

    const handleEditorUpdate = useCallback((newContent) => {
        if (!isUpdatingRef.current) {
            const contentChanged = isContentChanged(newContent, lastSavedContentRef.current);
            setHasUnsavedChanges(contentChanged);
            if (onUpdate) {
                onUpdate(newContent);
            }
            if (contentChanged) {
                if (autoSaveTimeoutRef.current) {
                    clearTimeout(autoSaveTimeoutRef.current);
                }
                autoSaveTimeoutRef.current = setTimeout(() => {
                    handleAutoSave(newContent);
                }, 5000);
            }
        }
    }, [onUpdate, isContentChanged, setHasUnsavedChanges]);

    const handleAutoSave = useCallback((content) => {

        if (isContentChanged(content, lastSavedContentRef.current)) {
            onSave(content).then(success => {
                if (success) {
                    lastSavedContentRef.current = content;
                    setHasUnsavedChanges(false);
                }
            });
        }
    }, [onSave, isContentChanged, setHasUnsavedChanges]);

    const editor = useTiptapConfig(initialContent, handleEditorUpdate, () => {
        setIsEditorReady(true);
    });
    editorRef.current = editor;

    useEffect(() => {
        setIsTouchDevice('ontouchstart' in window || navigator.maxTouchPoints > 0);
    }, []);

    useEffect(() => {
        if (selectedDate.toISOString() !== prevDateRef.current.toISOString()) {
            setEditorKey(selectedDate.toISOString());
            prevDateRef.current = selectedDate;
            lastSavedContentRef.current = initialContent;
            setHasUnsavedChanges(false);
        }
    }, [selectedDate, initialContent, setHasUnsavedChanges]);

    useEffect(() => {
        if (editor && isEditorReady && initialContent !== editor.getJSON()) {
            isUpdatingRef.current = true;
            requestAnimationFrame(() => {
                editor.commands.setContent(initialContent, false);
                lastSavedContentRef.current = initialContent;
                setHasUnsavedChanges(false);
                isUpdatingRef.current = false;
            });
        }
    }, [editor, initialContent, editorKey, isEditorReady, setHasUnsavedChanges]);

    useEffect(() => {
        lastSavedContentRef.current = lastSavedContent;
    }, [lastSavedContent]);

    const [isToolbarVisible, setIsToolbarVisible] = useState(true);
    const [isToolbarPinned, setIsToolbarPinned] = useState(false);

    const handleKeyDown = useCallback((event) => {
        if ((event.ctrlKey || event.metaKey) && event.key === 's') {
            // Handle save shortcut
            event.preventDefault();
            handleAutoSave(editor.getJSON());
        }
        // Add more keyboard shortcuts here if needed
    }, [handleAutoSave, editor]);

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]);

    const toggleToolbar = () => {
        setIsToolbarVisible(!isToolbarVisible);
    };

    const togglePin = () => {
        setIsToolbarPinned(!isToolbarPinned);
        setIsToolbarVisible(true);
    };

    const handleTouchStart = (e) => {
        setTouchStartY(e.touches[0].clientY);
    };

    const handleTouchMove = (e) => {
        if (touchStartY === null) return;

        const touchEndY = e.touches[0].clientY;
        const diff = touchStartY - touchEndY;

        if (diff > 50) {
            setIsToolbarVisible(false);
        } else if (diff < -50) {
            setIsToolbarVisible(true);
        }
    };

    const handleTouchEnd = () => {
        setTouchStartY(null);
    };

    if (!editor) {
        return null;
    }

    return (
        <EditorProvider value={editor} key={editorKey}>
            <div
                className={`tiptap-wrapper ${theme === 'dark' || theme === 'dark2' ? 'tiptap-dark' : ''} ${isTouchDevice ? 'touch-device' : ''}`}
                aria-label="Rich text editor"
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleTouchEnd}
            >
                <div className={`toolbar-container ${isToolbarPinned ? 'pinned' : ''}`}>
                    {isToolbarVisible && <EditorToolbar />}
                    <button
                        className="toolbar-toggle touch-friendly"
                        onClick={toggleToolbar}
                        aria-label={isToolbarVisible ? "Hide toolbar" : "Show toolbar"}
                        disabled={isToolbarPinned}
                    >
                        <FontAwesomeIcon icon={isToolbarVisible ? faAngleDoubleUp : faAngleDoubleDown} />
                    </button>
                    <button
                        className={`toolbar-pin touch-friendly ${isToolbarPinned ? 'active' : 'slashed'}`}
                        onClick={togglePin}
                        aria-label={isToolbarPinned ? "Unpin toolbar" : "Pin toolbar"}
                    >
                        <FontAwesomeIcon icon={faThumbtack} />
                    </button>
                </div>
                <div
                    className="tiptap-content-wrapper"
                    data-gramm_editor="true"
                    aria-label="Editor content area"
                >
                    <EditorContent onSave={handleAutoSave} />
                </div>
            </div>
        </EditorProvider>
    );
};

export default TiptapEditor;