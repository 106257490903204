import { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ModalMenu from './MobileMenuModal';
import { tableSubmenuConfig } from './toolbarConfig';

const TableSubmenu = ({ item, editor, isMobile }) => {
    const [isOpen, setIsOpen] = useState(false);
    const menuRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="btn-group me-2 mb-2" role="group" ref={menuRef}>
            <button
                type="button"
                className="btn btn-sm btn-outline-secondary border-0"
                onClick={toggleMenu}
                aria-label="Table options"
                aria-expanded={isOpen}
            >
                <FontAwesomeIcon icon={item.icon} />
            </button>
            {isMobile ? (
                <ModalMenu
                    isOpen={isOpen}
                    onClose={() => setIsOpen(false)}
                    title="Table Options"
                    menuItems={tableSubmenuConfig}
                    onItemClick={(subItem) => subItem.action(editor)}
                />
            ) : (
                <ul className={`dropdown-menu ${isOpen ? 'show' : ''}`} aria-label="Table options">
                    {tableSubmenuConfig.map((subItem, index) => (
                        <li key={index}>
                            <button
                                className="dropdown-item"
                                onClick={() => {
                                    subItem.action(editor);
                                    setIsOpen(false);
                                }}
                                aria-label={subItem.label}
                            >
                                <FontAwesomeIcon icon={subItem.icon} className="me-2" />
                                <span>{subItem.label}</span>
                            </button>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default TableSubmenu;