// Utility function to safely parse JSON
export const safeJsonParse = (jsonString) => {
  try {
    return JSON.parse(jsonString);
  } catch (e) {
    console.error('Failed to parse JSON:', e);
    return null;
  }
};

export default safeJsonParse;