import { useState, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import { useAuth } from '../../contexts/AuthContext';
import NEW_JournalApiService from '../../services/NEW_JournalApiService';

const SearchEntries = ({ onResultsUpdate }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [noResults, setNoResults] = useState(false);
  const { user } = useAuth();

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString().split('T')[0]; // This formats the date as YYYY-MM-DD
  };

  const formatSearchResults = useCallback((results, term) => {
    return results.map(result => {
      const date = formatDate(result.entry_date);
      const content = result.content;
      let text = "";

      content.content.forEach(item => {
        if (item.type === "paragraph" && item.content) {
          item.content.forEach(contentItem => {
            if (contentItem.type === "text") {
              text += contentItem.text.trim() + " ";
            }
          });
        }
      });

      text = text.trim();

      const index = text.toLowerCase().indexOf(term.toLowerCase());
      let formattedText = "";

      if (index !== -1) {
        const start = Math.max(0, index - 20);
        const end = Math.min(text.length, index + term.length + 40);
        formattedText = text.slice(start, end).trim();

        if (start > 0) formattedText = "..." + formattedText;
        if (end < text.length) formattedText += "...";

        const regex = new RegExp(`(${term})`, 'gi');
        formattedText = formattedText.replace(regex, '<strong>$1</strong>');
      } else {
        formattedText = text.slice(0, 40).trim() + "...";
      }

      return {
        date,
        formattedText,
        className: 'cursor-pointer hover:bg-gray-200 p-1 rounded mb-1'
      };
    });
  }, []);

  const performSearch = useCallback(async (term) => {
    if (!term.trim()) return;

    try {
      setIsLoading(true);
      setError("");
      setNoResults(false);
      const response = await NEW_JournalApiService.searchEntries(user.username, term);

      if (response && response.results) {
        const formatted = formatSearchResults(response.results, term);
        if (formatted.length === 0) {
          setNoResults(true);
        } else {
          setNoResults(false);
        }
        onResultsUpdate(formatted);
      } else {
        setNoResults(true);
        onResultsUpdate([]);
      }
    } catch (error) {
      console.error("Error during search:", error);
      setError("Failed to perform search. Please try again.");
      onResultsUpdate([]);
    } finally {
      setIsLoading(false);
    }
  }, [user.username, formatSearchResults, onResultsUpdate]);

  const handleSearch = useCallback((e) => {
    e.preventDefault();
    performSearch(searchTerm);
  }, [searchTerm, performSearch]);

  const handleClearSearch = useCallback(() => {
    setSearchTerm("");
    setError("");
    setNoResults(false);
    onResultsUpdate([]);
  }, [onResultsUpdate]);

  return (
    <div className="mb-1">
      <form onSubmit={handleSearch} className="mb-1">
        <div className="input-group">
          <input
            type="text"
            className="form-control"
            placeholder="Search entries..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            disabled={isLoading}
          />
          {searchTerm && (
            <button
              type="button"
              className="btn btn-outline-secondary"
              onClick={handleClearSearch}
              disabled={isLoading}
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
          )}
          <button
            className="btn btn-outline-secondary"
            type="submit"
            disabled={isLoading}
          >
            <FontAwesomeIcon icon={faSearch} />
          </button>
        </div>
      </form>
      {isLoading && <p className="text-center">Loading...</p>}
      {error && <p className="text-danger">{error}</p>}
      {noResults && <p className="text-center">No results found.</p>}
    </div>
  );
};

export default SearchEntries;