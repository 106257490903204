import { Extension } from '@tiptap/core'
import { Plugin, NodeSelection } from '@tiptap/pm/state'

export const ImageSelection = Extension.create({
    name: 'imageSelection',

    addProseMirrorPlugins() {
        return [
            new Plugin({
                props: {
                    handleClick(view, pos, event) {
                        const { state } = view
                        const $pos = state.doc.resolve(pos)

                        // Check if the click is directly on an image node
                        const node = $pos.parent.nodeAt($pos.parentOffset)

                        if (node && node.type.name === 'image') {
                            const start = $pos.start()
                            const imagePos = start + $pos.parentOffset
                            const selection = NodeSelection.create(state.doc, imagePos)
                            view.dispatch(state.tr.setSelection(selection))
                            return true
                        }

                        return false
                    },
                },
            }),
        ]
    },
})