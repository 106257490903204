import { useState, useEffect, useCallback, useRef, lazy, Suspense } from "react";
import { Navbar, Container, Row, Col } from 'react-bootstrap';
import { useAuth } from '../../contexts/AuthContext';

import CalendarGrid from "./CalendarGrid";
import TiptapEditor from "../Tiptap/TiptapEditor";
import {
  formatDate,
  getNextMonth,
  getPreviousMonth,
  getDaysInMonth,
  parseDateString,
  createEmptyEntry,
} from "../../utils/dateUtils";
import CustomAlert from "./CustomSaveAlert"; // Adjust the path as necessary
import { safeJsonParse } from "../../utils/functionUtils";
import SearchEntries from "./SearchEntries";
import CalendarHeader from "./CalendarHeader";
import SaveSection from "./SaveSection";
import AppFooter from "../AppFooter";
const Sidebar = lazy(() => import("./Sidebar"));
import 'react-toastify/dist/ReactToastify.css';
import SaveHandler from "./SaveHandler";
import UndoneTasksManager from './UndoneTasksManager';



const Calendar = () => {
  const [currentDate, setCurrentDate] = useState(() => {
    const savedDate = localStorage.getItem("currentDate");
    return savedDate ? new Date(savedDate) : new Date();
  });
  const [previousDate, setPreviousDate] = useState(new Date());
  const [showAlert, setShowAlert] = useState(false);
  const [alertCallback, setAlertCallback] = useState(null);
  const [entries, setEntries] = useState({});
  const [selectedDate, setSelectedDate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const [searchResults, setSearchResults] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [noResultsFound, setNoResultsFound] = useState(false);

  const [unsavedEntries, setUnsavedEntries] = useState({});
  const [currentContent, setCurrentContent] = useState(null);
  const undoneTasksManagerRef = useRef();
  const [isInitialLoadComplete, setIsInitialLoadComplete] = useState(false);

  const fetchedRef = useRef(false);

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [sidebarWidth, setSidebarWidth] = useState(() => {
    const savedWidth = localStorage.getItem("sidebarWidth");
    return savedWidth ? parseInt(savedWidth, 10) : 100;
  });
  const [isCalendarVisible, setIsCalendarVisible] = useState(true);
  const [isSearchVisible, setISearchVisible] = useState(false);
  const [isSidebarLoaded, setIsSidebarLoaded] = useState(false);
  const [apiService, setApiService] = useState(null);

  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [lastAutoSaveTime, setLastAutoSaveTime] = useState(null);
  const lastSavedContentRef = useRef(null);
  const [saveError, setSaveError] = useState(null);

  const { user } = useAuth();

  const isDateInFuture = useCallback((date) => {
    const now = new Date();
    const today = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate()));
    return date > today;
  }, []);


  const fetchTodaysEntries = useCallback(async () => {
    if (!apiService) {
      console.log("API service not yet loaded");
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const today = new Date();
      let fetchedEntry = await apiService.getEntry(user.username, today);
      const dateKey = formatDate(today);

      if (typeof fetchedEntry.content === 'string') {
        fetchedEntry.content = safeJsonParse(fetchedEntry.content);
      }

      if (fetchedEntry.content && fetchedEntry.content.type === 'doc') {
        setEntries(prevEntries => ({ ...prevEntries, [dateKey]: fetchedEntry.content }));
        setCurrentContent(fetchedEntry.content);
      } else {
        console.error('Invalid Tiptap content structure:', fetchedEntry.content);
        setCurrentContent(createEmptyEntry(today));
      }

      setSelectedDate(today);
      setCurrentDate(today);
      setIsInitialLoadComplete(true);

    } catch (err) {
      console.error("Failed to fetch today's entry:", err);
      setError("Failed to fetch today's entry. Please try again later.");
      setCurrentContent(createEmptyEntry(new Date()));
    } finally {
      setIsLoading(false);
    }
  }, [user.username, apiService]);

  useEffect(() => {
    if (apiService) {
      fetchTodaysEntries();
    }
  }, [fetchTodaysEntries, apiService]);

  // useEffect for checking undone tasks after initial load
  useEffect(() => {
    if (isInitialLoadComplete && undoneTasksManagerRef.current && typeof undoneTasksManagerRef.current.checkForUndoneTasks === 'function') {
      const today = new Date();
      undoneTasksManagerRef.current.checkForUndoneTasks(today)
        .catch(error => console.error("Error checking for undone tasks:", error));
    }
  }, [isInitialLoadComplete]);


  useEffect(() => {
    Promise.all([
      import("./Sidebar"),
      import("../../services/NEW_JournalApiService")
    ]).then(([sidebarModule, apiServiceModule]) => {
      setIsSidebarLoaded(true);
      setApiService(apiServiceModule.default);
    });
  }, []);

  const { handleSave } = SaveHandler({
    apiService,
    selectedDate,
    unsavedEntries,
    currentContent,
    setEntries,
    setUnsavedEntries,
    setCurrentContent,
    setError,
    setIsLoading,
    setHasUnsavedChanges,
    setLastAutoSaveTime,
    setSaveError
  });


  const handleDateClick = useCallback(async (day) => {

    if (day.getTime() === selectedDate?.getTime()) return;

    const processDayChange = async () => {

      if (!apiService) {
        console.log("API service not yet loaded");
        return;
      }

      setIsLoading(true);
      try {
        // const dateKey = formatDateKey(day);
        const dateKey = formatDate(day);
        let entryData;

        if (entries[dateKey]) {
          entryData = entries[dateKey];
        } else {
          let fetchedEntry = await apiService.getEntry(user.username, day);

          // Parse the content if it's a string
          if (typeof fetchedEntry.content === 'string') {
            fetchedEntry.content = safeJsonParse(fetchedEntry.content);
          }

          // Ensure the content is in the correct format
          if (fetchedEntry.content && fetchedEntry.content.type === 'doc') {
            entryData = fetchedEntry.content;
            setEntries(prevEntries => ({ ...prevEntries, [dateKey]: entryData }));
          } else {
            console.error('Invalid Tiptap content structure:', fetchedEntry.content);
            entryData = createEmptyEntry(day);
          }
        }

        setSelectedDate(day);
        setCurrentContent(entryData);
        setPreviousDate(day);
        setCurrentDate(day);

        if (unsavedEntries[dateKey]) {
          setCurrentContent(unsavedEntries[dateKey]);
        }

        if (day.toDateString() === new Date().toDateString()) {
          // Non-blocking call to checkForUndoneTasks
          undoneTasksManagerRef.current.checkForUndoneTasks(day)
            .catch(error => console.error("Error checking for undone tasks:", error));
        }

      } catch (err) {
        console.error("Failed to fetch entry:", err);
        setError("Failed to fetch entry. Please try again.");
        setCurrentContent(createEmptyEntry(day));
      } finally {
        setIsLoading(false);
      }
    };

    if (
      previousDate &&
      (day.getDate() !== previousDate.getDate() ||
        day.getMonth() !== previousDate.getMonth() ||
        day.getFullYear() !== previousDate.getFullYear())
    ) {
      // const previousDateKey = formatDateKey(previousDate);
      const previousDateKey = formatDate(previousDate);
      if (unsavedEntries[previousDateKey]) {
        setShowAlert(true);
        return new Promise((resolve) => {
          setAlertCallback(() => async (shouldSave) => {
            setShowAlert(false);
            if (shouldSave) {
              await handleSave();
            } else {
              // Discard changes
              const newUnsavedEntries = { ...unsavedEntries };
              delete newUnsavedEntries[previousDateKey];
              setUnsavedEntries(newUnsavedEntries);
            }
            await processDayChange();
            resolve();
          });
        });
      }
    }

    return processDayChange();
  }, [previousDate, unsavedEntries, entries, selectedDate, user.username, handleSave]);


  const handleEditorUpdate = useCallback((content) => {
    if (selectedDate) {
      const dateKey = formatDate(selectedDate);
      setUnsavedEntries((prevUnsaved) => {
        const newUnsaved = {
          ...prevUnsaved,
          [dateKey]: content,
        };
        return newUnsaved;
      });
      setCurrentContent(content);
      // We'll let TiptapEditor handle setting hasUnsavedChanges
    }
  }, [selectedDate]);

  const handleAutoSave = useCallback(async (content) => {
    if (selectedDate) {
      setSaveError(null);  // Reset save error before attempting to save
      const success = await handleSave(content);
      if (success) {
        lastSavedContentRef.current = content;
        setHasUnsavedChanges(false);
        setShowAlert(false);  // Hide the alert when auto-save is successful
        setLastAutoSaveTime(new Date());
      } else {
        setSaveError("Failed to save. Please try again.");
      }
      return success;
    }
    console.log('Auto-save not attempted: no selected date');
    return false;
  }, [selectedDate, handleSave]);


  const handleManualSave = useCallback(async () => {
    if (hasUnsavedChanges) {
      setSaveError(null);  // Reset save error before attempting to save
      const success = await handleSave(currentContent);
      if (success) {
        setHasUnsavedChanges(false);
        setLastAutoSaveTime(new Date());
      } else {
        setSaveError("Failed to save. Please try again.");
      }
    }
  }, [hasUnsavedChanges, currentContent, handleSave]);


  const goToToday = useCallback(() => {
    const today = new Date();
    setCurrentDate(today);
    handleDateClick(today);
  }, [handleDateClick, setCurrentDate]);

  useEffect(() => {
    localStorage.setItem("currentDate", currentDate.toISOString());
  }, [currentDate]);

  const prevMonth = () => setCurrentDate(getPreviousMonth(currentDate));
  const nextMonth = () => setCurrentDate(getNextMonth(currentDate));

  const handleKeyDown = useCallback(
    (e) => {
      if ((e.ctrlKey || e.metaKey) && e.key === "s") {
        e.preventDefault();
        if (showAlert) {
          setShowAlert(false);
          alertCallback(true);
        } else {
          handleSave();
        }
      } else if (e.key === "Escape" && showAlert) {
        setShowAlert(false);
        alertCallback(false);
      }
    },
    [handleSave, showAlert, alertCallback]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  useEffect(() => {
    const savedWidth = localStorage.getItem("sidebarWidth");
    if (savedWidth) {
      setSidebarWidth(parseInt(savedWidth, 10));
    }
  }, []);

  const toggleSidebar = useCallback(() => {
    setIsSidebarOpen((prevState) => !prevState);
  }, []);

  const handleSidebarWidthChange = useCallback((newWidth) => {
    setSidebarWidth(newWidth);
    localStorage.setItem("sidebarWidth", newWidth.toString());
  }, []);

  const goToPreviousDate = useCallback(() => {
    if (selectedDate) {
      const previousDate = new Date(selectedDate);
      previousDate.setDate(previousDate.getDate() - 1);
      handleDateClick(previousDate);
    }
  }, [selectedDate, handleDateClick]);

  const goToNextDate = useCallback(() => {
    if (selectedDate) {
      const nextDate = new Date(selectedDate);
      nextDate.setDate(nextDate.getDate() + 1);
      handleDateClick(nextDate);
    }
  }, [selectedDate, handleDateClick]);

  const toggleCalendarVisibility = () => {
    setIsCalendarVisible(prev => !prev);
  };

  // Undone Tasks Stuff
  // ==================

  const handleTasksMoved = useCallback((updatedContent) => {
    setCurrentContent(updatedContent);
    fetchTodaysEntries(); // Refresh the entries
  }, [fetchTodaysEntries]);

  const updateEntry = useCallback((dateKey, content) => {
    setEntries(prevEntries => ({
      ...prevEntries,
      [dateKey]: content
    }));

    // If the updated entry is the currently selected date, update the current content
    if (dateKey === formatDate(selectedDate)) {
      setCurrentContent(content);
    }
  }, [selectedDate, formatDate]);

  // Search results stuff
  // ====================

  const handleSearchResultClick = (dateString) => {
    const date = parseDateString(dateString);
    if (date) {
      setCurrentDate(date);
      handleDateClick(date);
    } else {
      setError(`Unable to view entry for invalid date: ${dateString}`);
    }
  };


  const toggleSearchVisibility = () => {
    setISearchVisible(prev => !prev);
  };

  const handleSearchResultsUpdate = useCallback((results) => {
    setSearchResults(results);
  }, []);

  const handleResultClick = useCallback((dateString) => {
    handleSearchResultClick(dateString);
  }, [handleSearchResultClick]);

  // Calculate and set the viewport height CSS variable
  useEffect(() => {
    const setViewportHeight = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    setViewportHeight();
    window.addEventListener('resize', setViewportHeight);

    return () => {
      window.removeEventListener('resize', setViewportHeight);
    };
  }, []);

  return (
    <div className="d-flex flex-column vh-100">
      <div
        className="d-flex flex-column"
        style={{ height: 'calc(var(--vh, 1vh) * 100)' }}
      >
        <div className="flex-grow-1 overflow-hidden">
          <main
            className="h-100 d-flex"
            style={{
              transition: "margin-left 0.3s, width 0.3s",
            }}
          >
            {isSidebarLoaded && (
              <Suspense fallback={<div>Loading...</div>}>
                <Sidebar
                  isOpen={isSidebarOpen}
                  onToggle={toggleSidebar}
                  onWidthChange={handleSidebarWidthChange}
                  defaultWidth={sidebarWidth}
                  handleSearchResultClick={handleSearchResultClick}
                  isLoading={isLoading}
                />
              </Suspense>
            )}
            <div
              className="flex-grow-1 d-flex flex-column overflow-hidden"
              style={{
                marginLeft: isSidebarOpen ? `${sidebarWidth + 5}px` : "0",
                width: isSidebarOpen ? `calc(100% - ${sidebarWidth + 5}px)` : "100%",
              }}
            >

              {selectedDate && (
                <div className="flex-grow-1 d-flex flex-column overflow-hidden">
                  <div className="p-3">
                    <div className="d-flex justify-content-between align-items-center mb-2">
                      <CalendarHeader
                        currentDate={currentDate}
                        selectedDate={selectedDate}
                        prevMonth={prevMonth}
                        nextMonth={nextMonth}
                        goToPreviousDate={goToPreviousDate}
                        goToNextDate={goToNextDate}
                      />
                      <SaveSection
                        goToToday={goToToday}
                        handleManualSave={handleManualSave}
                        isLoading={isLoading}
                        hasUnsavedChanges={hasUnsavedChanges}
                        lastAutoSaveTime={lastAutoSaveTime}
                        saveError={saveError}
                      />
                    </div>

                    {showAlert && (
                      <CustomAlert
                        onSave={() => alertCallback(true)}
                        onDiscard={() => alertCallback(false)}
                        show={showAlert && hasUnsavedChanges}
                      />
                    )}
                    {error && <div className="alert alert-danger mt-3">{error}</div>}
                    {isCalendarVisible && (
                      <CalendarGrid
                        currentDate={currentDate}
                        days={getDaysInMonth(currentDate)}
                        entries={entries}
                        selectedDate={selectedDate}
                        handleDateClick={handleDateClick}
                        unsavedEntries={unsavedEntries}
                      />
                    )}
                    {isSearchVisible && (
                      <SearchEntries onResultsUpdate={handleSearchResultsUpdate} />
                    )}
                    {searchResults.length > 0 && (
                      <ul className="list-unstyled mt-3">
                        {searchResults.map((result, index) => (
                          <li
                            key={index}
                            className={result.className}
                            onClick={() => handleResultClick(result.date)}
                          >
                            <span className="fw-bold me-1">{result.date}:</span>
                            <span dangerouslySetInnerHTML={{ __html: result.formattedText }} />
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>

                  <div className="flex-grow-1 overflow-auto p-3 pt-0">
                    {currentContent && (
                      <TiptapEditor
                        initialContent={currentContent}
                        onUpdate={handleEditorUpdate}
                        onSave={handleAutoSave}
                        key={selectedDate.toISOString()}
                        selectedDate={selectedDate}
                        lastSavedContent={lastSavedContentRef.current}
                        hasUnsavedChanges={hasUnsavedChanges}
                        setHasUnsavedChanges={setHasUnsavedChanges}
                      />
                    )}
                  </div>
                  <UndoneTasksManager
                    ref={undoneTasksManagerRef}
                    apiService={apiService}
                    // user={user}
                    selectedDate={selectedDate}
                    onTasksMoved={handleTasksMoved}
                    setError={setError}
                    updateEntry={updateEntry}
                  />
                </div>
              )}
            </div>
          </main>
        </div>

        <footer className="bg-light w-100">
          <Container fluid className="ps-0 pe-0">
            <AppFooter
              toggleSidebar={toggleSidebar}
              toggleCalendarVisibility={toggleCalendarVisibility}
              toggleSearchVisibility={toggleSearchVisibility}
              selectedDate={selectedDate}
              hasUnsavedChanges={hasUnsavedChanges}
              isLoading={isLoading}
            />
          </Container>
        </footer>
      </div>
    </div>
  );

};

export default Calendar;
