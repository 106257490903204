// CalendarGrid.jsx

// import React from "react";
import {
  isCurrentMonth,
  isCurrentDay,
  isSameDayAs,
  formatDayNumber,
} from "../../utils/dateUtils";
import { DAYS_OF_WEEK } from "./constants";

const formatDateKey = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

const CalendarGrid = ({
  currentDate,
  days,
  entries,
  selectedDate,
  handleDateClick,
  unsavedEntries,
}) => {
  const isWeekend = (dayIndex) => {
    return dayIndex === 5 || dayIndex === 6; // 5 is Saturday, 6 is Sunday (0-indexed, starting from Monday)
  };


  const hasEntry = (day) => {
    const dateKey = formatDateKey(day);
    return entries && entries[dateKey] && Object.keys(entries[dateKey]).length > 0;
  };

  const isUnsaved = (day) => {
    const dateKey = formatDateKey(day);
    return unsavedEntries && unsavedEntries[dateKey];
  };

  return (
    <table className="table table-bordered mb-0 pb-0">
      <thead>
        <tr>
          {DAYS_OF_WEEK.map((day, index) => (
            <th
              key={day}
              className={`text-center ${isWeekend(index) ? "text-danger" : ""}`}
            >
              {day}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {Array.from({ length: Math.ceil(days.length / 7) }).map(
          (_, weekIndex) => (
            <tr key={weekIndex}>
              {days.slice(weekIndex * 7, (weekIndex + 1) * 7).map((day) => {
                const entryExists = hasEntry(day);
                const isSelected = isSameDayAs(day, selectedDate);
                const unsaved = isUnsaved(day);

                return (
                  <td
                    key={day.toString()}
                    className={`text-center p-1
                    ${!isCurrentMonth(day, currentDate) ? "text-secondary" : ""}
                    ${!isCurrentMonth(day, currentDate) ? "bg-body" : ""}
                    ${isCurrentDay(day) ? "bg-primary-subtle" : ""}
                    ${entryExists ? "bg-light-red" : ""}
                    ${isSelected ? "border border-primary" : ""}`}
                    onClick={() => handleDateClick(day)}
                    style={{ cursor: "pointer", position: "relative" }}
                  >
                    <span className={entryExists ? "fw-bold" : ""}>
                      {formatDayNumber(day)}
                    </span>
                  </td>
                );
              })}
            </tr>
          )
        )}
      </tbody>
    </table>
  );
};

export default CalendarGrid;
