import { Node } from '@tiptap/core';
import NEW_JournalApiService from '../../services/NEW_JournalApiService'; // Adjust path as needed

// Create a cache to store image URLs and loading promises
const imageUrlCache = new Map();
const loadingPromises = new Map();

// Debounce function
const debounce = (func, wait) => {
    let timeout;
    return (...args) => {
        clearTimeout(timeout);
        return new Promise((resolve) => {
            timeout = setTimeout(() => resolve(func(...args)), wait);
        });
    };
};

const CustomImage = Node.create({
    name: 'image',
    group: 'inline',
    inline: true,
    selectable: true,
    draggable: true,

    addAttributes() {
        return {
            src: { default: null },
            alt: { default: null },
            title: { default: null },
            'data-filename': { default: null },
            'data-id': { default: null },
            'data-username': { default: null },
            'data-date': { default: null },
            width: {
                default: null,
                parseHTML: element => element.getAttribute('width') || null,
                renderHTML: attributes => {
                    // console.log('Rendering width attribute:', attributes.width);
                    return attributes.width ? { width: attributes.width } : {};
                },
            },
            height: {
                default: null,
                parseHTML: element => element.getAttribute('height') || null,
                renderHTML: attributes => {
                    // console.log('Rendering height attribute:', attributes.height);
                    return attributes.height ? { height: attributes.height } : {};
                },
            },
        };
    },

    parseHTML() {
        return [
            {
                tag: 'img[src]',
                getAttrs: (dom) => {
                    const attrs = {
                        src: dom.getAttribute('src'),
                        alt: dom.getAttribute('alt'),
                        title: dom.getAttribute('title'),
                        'data-filename': dom.getAttribute('data-filename'),
                        'data-id': dom.getAttribute('data-id'),
                        'data-username': dom.getAttribute('data-username'),
                        'data-date': dom.getAttribute('data-date'),
                        width: dom.getAttribute('width'),
                        height: dom.getAttribute('height'),
                    };
                    // console.log('Parsed HTML attributes:', attrs);
                    return attrs;
                },
            },
        ];
    },

    renderHTML({ HTMLAttributes }) {
        // console.log('Rendering HTML with attributes:', HTMLAttributes);
        return ['img', {
            ...HTMLAttributes,
            width: HTMLAttributes.width,
            height: HTMLAttributes.height
        }];
    },

    addNodeView() {
        return ({ node, getPos, editor }) => {
            const container = document.createElement('span');
            container.classList.add('tiptap-custom-image-container');
            container.style.display = 'inline-block';
            container.style.position = 'relative';

            const img = document.createElement('img');
            container.appendChild(img);

            const applyImageDimensions = () => {
                if (node.attrs.width) {
                    container.style.setProperty('--image-width', node.attrs.width);
                    img.style.width = node.attrs.width;
                }
                if (node.attrs.height) {
                    container.style.setProperty('--image-height', node.attrs.height);
                    img.style.height = node.attrs.height;
                }
                // console.log('Applied dimensions:', { width: img.style.width, height: img.style.height });
            };

            const errorOverlay = document.createElement('span');
            errorOverlay.textContent = '⚠️ Image failed to load';
            errorOverlay.style.display = 'none';
            errorOverlay.style.position = 'absolute';
            errorOverlay.style.top = '0';
            errorOverlay.style.left = '10';
            errorOverlay.style.right = '0';
            errorOverlay.style.bottom = '0';
            errorOverlay.style.backgroundColor = 'rgba(255, 255, 255, 0.8)';
            errorOverlay.style.color = 'red';
            errorOverlay.style.padding = '5px';
            errorOverlay.style.fontSize = '12px';
            errorOverlay.style.textAlign = 'center';
            container.appendChild(errorOverlay);

            const showError = () => {
                console.error('Failed to load image:', node.attrs.src);
                errorOverlay.style.display = 'flex';
                errorOverlay.style.alignItems = 'center';
                errorOverlay.style.justifyContent = 'center';
                img.style.opacity = '0.2';
            };

            img.addEventListener('error', showError);

            const resizeHandle = document.createElement('div');
            resizeHandle.style.position = 'absolute';
            resizeHandle.style.bottom = '0px';
            resizeHandle.style.right = '0px';
            resizeHandle.style.width = '20px';
            resizeHandle.style.height = '20px';
            resizeHandle.style.border = '2px solid rgba(255, 255, 255, 0.7)';
            resizeHandle.style.borderTop = 'none';
            resizeHandle.style.borderLeft = 'none';
            resizeHandle.style.cursor = 'se-resize';
            resizeHandle.style.borderRadius = '0 0 2px 0';
            resizeHandle.style.boxShadow = '0 0 2px rgba(0, 0, 0, 0.5)';
            container.appendChild(resizeHandle);

            let resizing = false;
            let startX, startY, startWidth, startHeight;

            const startResize = (e) => {
                resizing = true;
                startX = e.clientX || e.touches[0].clientX;
                startY = e.clientY || e.touches[0].clientY;
                startWidth = img.offsetWidth;
                startHeight = img.offsetHeight;
                document.addEventListener('mousemove', resize);
                document.addEventListener('mouseup', stopResize);
                document.addEventListener('touchmove', resize);
                document.addEventListener('touchend', stopResize);

                // Add hover effect for better visibility on desktop
                container.addEventListener('mouseenter', () => {
                    resizeHandle.style.opacity = '1';
                });
                container.addEventListener('mouseleave', () => {
                    resizeHandle.style.opacity = '0';
                });

                // Show handle on touch start for mobile devices
                container.addEventListener('touchstart', () => {
                    resizeHandle.style.opacity = '1';
                });

                // Hide handle after a delay when touch ends
                container.addEventListener('touchend', () => {
                    setTimeout(() => {
                        resizeHandle.style.opacity = '0';
                    }, 1500); // Hide after 1.5 seconds
                });

                e.preventDefault();
            };

            const resize = (e) => {
                if (!resizing) return;
                const clientX = e.clientX || e.touches[0].clientX;
                const clientY = e.clientY || e.touches[0].clientY;
                const width = startWidth + clientX - startX;
                const height = startHeight + clientY - startY;
                img.style.width = `${width}px`;
                img.style.height = `${height}px`;
                const pos = getPos();
                editor.view.dispatch(editor.view.state.tr.setNodeMarkup(pos, null, {
                    ...node.attrs,
                    width: `${width}px`,
                    height: `${height}px`,
                }));

                if (editor.options.customImageOptions?.onImageResize) {
                    editor.options.customImageOptions.onImageResize(node.attrs, width, height);
                }
            };

            const stopResize = () => {
                resizing = false;
                document.removeEventListener('mousemove', resize);
                document.removeEventListener('mouseup', stopResize);
                document.removeEventListener('touchmove', resize);
                document.removeEventListener('touchend', stopResize);
            };

            resizeHandle.addEventListener('mousedown', startResize);
            resizeHandle.addEventListener('touchstart', startResize);

            // Show handle on mouse enter for desktop devices
            container.addEventListener('mouseenter', () => {
                resizeHandle.style.opacity = '1';
            });

            // Hide handle on mouse leave for desktop devices
            container.addEventListener('mouseleave', () => {
                if (!resizing) {
                    resizeHandle.style.opacity = '0';
                }
            });

            // Show handle on touch start for mobile devices
            container.addEventListener('touchstart', () => {
                resizeHandle.style.opacity = '1';
            });

            // Hide handle after a delay when touch ends
            container.addEventListener('touchend', () => {
                if (!resizing) {
                    setTimeout(() => {
                        resizeHandle.style.opacity = '0';
                    }, 1500); // Hide after 1.5 seconds
                }
            });

            const loadImage = debounce(async () => {
                const { src, width, height } = node.attrs;
                // console.log('Loading image with attributes:', { src, width, height });

                const applyDimensions = () => {
                    if (width) {
                        container.style.setProperty('--image-width', width);
                        img.style.width = width;
                    }
                    if (height) {
                        container.style.setProperty('--image-height', height);
                        img.style.height = height;
                    }
                    // console.log('Applied dimensions:', { width: img.style.width, height: img.style.height });
                };

                if (src.startsWith('blob:') || src.startsWith('http')) {
                    img.onload = () => {
                        applyImageDimensions();
                        // console.log('Image loaded, applied dimensions:', {
                        //     width: img.style.width,
                        //     height: img.style.height
                        // });
                    };
                    img.src = src;
                    return;
                }

                const username = node.attrs['data-username'] ||
                    editor.options.customImageOptions.username ||
                    'anonymous';
                let date = node.attrs['data-date'];
                const filename = node.attrs['data-filename'] || src.split('/').pop();

                if (!date) {
                    const segments = src.split('/');
                    const dateIndex = segments.indexOf(username) + 1;
                    date = segments[dateIndex];
                }

                const cacheKey = `${username}:${date}:${filename}`;

                if (imageUrlCache.has(cacheKey)) {
                    img.src = imageUrlCache.get(cacheKey);
                    img.onload = applyDimensions;
                    return;
                }

                if (loadingPromises.has(cacheKey)) {
                    const imageUrl = await loadingPromises.get(cacheKey);
                    img.src = imageUrl;
                    img.onload = applyDimensions;
                    return;
                }

                const loadPromise = NEW_JournalApiService.getImage(username, date, filename)
                    .then((imageUrl) => {
                        imageUrlCache.set(cacheKey, imageUrl);
                        loadingPromises.delete(cacheKey);
                        return imageUrl;
                    })
                    .catch((error) => {
                        console.error('Error loading image:', error);
                        loadingPromises.delete(cacheKey);
                        showError();
                        throw error;
                    });

                loadingPromises.set(cacheKey, loadPromise);

                try {
                    const imageUrl = await loadPromise;
                    img.onload = () => {
                        applyImageDimensions();
                        // console.log('Image loaded from API, applied dimensions:', {
                        //     width: img.style.width,
                        //     height: img.style.height
                        // });
                    };
                    img.src = imageUrl;
                } catch (error) {
                    // Error already handled in the promise catch block
                }
            }, 50);

            loadImage();

            return {
                dom: container,
                update: (updatedNode) => {
                    if (updatedNode.type !== node.type) return false;

                    const srcChanged = updatedNode.attrs.src !== node.attrs.src;
                    const altChanged = updatedNode.attrs.alt !== node.attrs.alt;
                    const titleChanged = updatedNode.attrs.title !== node.attrs.title;
                    const widthChanged = updatedNode.attrs.width !== node.attrs.width;
                    const heightChanged = updatedNode.attrs.height !== node.attrs.height;

                    node = updatedNode;

                    if (altChanged) img.setAttribute('alt', node.attrs.alt || '');
                    if (titleChanged) img.setAttribute('title', node.attrs.title || '');
                    if (widthChanged || heightChanged || srcChanged) {
                        loadImage();
                    }

                    // console.log('Updated node attributes:', node.attrs);

                    return true;
                },
                destroy: () => {
                    if (img.src.startsWith('blob:')) URL.revokeObjectURL(img.src);
                    resizeHandle.removeEventListener('mousedown', startResize);
                    resizeHandle.removeEventListener('touchstart', startResize);
                    container.removeEventListener('mouseenter', () => { });
                    container.removeEventListener('mouseleave', () => { });
                    container.removeEventListener('touchstart', () => { });
                    container.removeEventListener('touchend', () => { });
                },
            };
        };
    },

    addCommands() {
        return {
            setImage: options => ({ commands }) => {
                return commands.insertContent({
                    type: this.name,
                    attrs: options,
                });
            },
        };
    },
});

export default CustomImage;