// toolbarConfig.jsx
import {
    faBold,
    faItalic,
    faUnderline,
    faStrikethrough,
    faHeading,
    faListUl,
    faListOl,
    faLink,
    faImage,
    faTable,
    faCode,
    faCheckSquare,
    faPlus,
    faMinus,
    faVideo,
    faParagraph,
    faChevronDown,
    faArrowLeft,
    faArrowRight,
    faArrowUp,
    faArrowDown,
    faTrash,
    faSuperscript,
    faSubscript,
    faAlignLeft,
    faAlignCenter,
    faAlignRight,
    faAlignJustify,
    faCaretSquareDown,
    faHighlighter,
    faFont,
    faTextHeight,
    faPencilAlt
} from '@fortawesome/free-solid-svg-icons';

export const toolbarConfig = [
    {
        type: 'dropdown',
        label: 'Style',
        icon: faHeading,
        items: [
            {
                label: 'Normal text',
                icon: faParagraph,
                action: (editor) => editor.chain().focus().setParagraph().run(),
                isActive: (editor) => editor.isActive('paragraph'),
            },
            {
                label: 'Heading 1',
                icon: faHeading,
                action: (editor) => editor.chain().focus().toggleHeading({ level: 1 }).run(),
                isActive: (editor) => editor.isActive('heading', { level: 1 }),
            },
            {
                label: 'Heading 2',
                icon: faHeading,
                action: (editor) => editor.chain().focus().toggleHeading({ level: 2 }).run(),
                isActive: (editor) => editor.isActive('heading', { level: 2 }),
            },
            {
                label: 'Heading 3',
                icon: faHeading,
                action: (editor) => editor.chain().focus().toggleHeading({ level: 3 }).run(),
                isActive: (editor) => editor.isActive('heading', { level: 3 }),
            },
            {
                label: 'Heading 4',
                icon: faHeading,
                action: (editor) => editor.chain().focus().toggleHeading({ level: 4 }).run(),
                isActive: (editor) => editor.isActive('heading', { level: 4 }),
            },
        ],
    },
    {
        type: 'dropdown',
        label: 'Font',
        icon: faFont,
        items: [
            {
                label: 'Arial',
                action: (editor) => editor.chain().focus().setFontFamily('Arial').run(),
                isActive: (editor) => editor.isActive('textStyle', { fontFamily: 'Arial' }),
            },
            {
                label: 'Times New Roman',
                action: (editor) => editor.chain().focus().setFontFamily('Times New Roman').run(),
                isActive: (editor) => editor.isActive('textStyle', { fontFamily: 'Times New Roman' }),
            },
            {
                label: 'Courier New',
                action: (editor) => editor.chain().focus().setFontFamily('Courier New').run(),
                isActive: (editor) => editor.isActive('textStyle', { fontFamily: 'Courier New' }),
            },
            {
                label: 'Georgia',
                action: (editor) => editor.chain().focus().setFontFamily('Georgia').run(),
                isActive: (editor) => editor.isActive('textStyle', { fontFamily: 'Georgia' }),
            },
            {
                label: 'Verdana',
                action: (editor) => editor.chain().focus().setFontFamily('Verdana').run(),
                isActive: (editor) => editor.isActive('textStyle', { fontFamily: 'Verdana' }),
            },
            {
                label: 'Helvetica',
                action: (editor) => editor.chain().focus().setFontFamily('Helvetica').run(),
                isActive: (editor) => editor.isActive('textStyle', { fontFamily: 'Helvetica' }),
            },
            {
                label: 'Tahoma',
                action: (editor) => editor.chain().focus().setFontFamily('Tahoma').run(),
                isActive: (editor) => editor.isActive('textStyle', { fontFamily: 'Tahoma' }),
            },
            {
                label: 'Trebuchet MS',
                action: (editor) => editor.chain().focus().setFontFamily('Trebuchet MS').run(),
                isActive: (editor) => editor.isActive('textStyle', { fontFamily: 'Trebuchet MS' }),
            },
            {
                label: 'Impact',
                action: (editor) => editor.chain().focus().setFontFamily('Impact').run(),
                isActive: (editor) => editor.isActive('textStyle', { fontFamily: 'Impact' }),
            },
            {
                label: 'Comic Sans MS',
                action: (editor) => editor.chain().focus().setFontFamily('Comic Sans MS').run(),
                isActive: (editor) => editor.isActive('textStyle', { fontFamily: 'Comic Sans MS' }),
            },
            {
                label: 'Palatino',
                action: (editor) => editor.chain().focus().setFontFamily('Palatino').run(),
                isActive: (editor) => editor.isActive('textStyle', { fontFamily: 'Palatino' }),
            },
            {
                label: 'Garamond',
                action: (editor) => editor.chain().focus().setFontFamily('Garamond').run(),
                isActive: (editor) => editor.isActive('textStyle', { fontFamily: 'Garamond' }),
            },
            {
                label: 'Bookman',
                action: (editor) => editor.chain().focus().setFontFamily('Bookman').run(),
                isActive: (editor) => editor.isActive('textStyle', { fontFamily: 'Bookman' }),
            },
            {
                label: 'Avant Garde',
                action: (editor) => editor.chain().focus().setFontFamily('Avant Garde').run(),
                isActive: (editor) => editor.isActive('textStyle', { fontFamily: 'Avant Garde' }),
            },
            {
                label: 'Candara',
                action: (editor) => editor.chain().focus().setFontFamily('Candara').run(),
                isActive: (editor) => editor.isActive('textStyle', { fontFamily: 'Candara' }),
            },
            {
                label: 'Geneva',
                action: (editor) => editor.chain().focus().setFontFamily('Geneva').run(),
                isActive: (editor) => editor.isActive('textStyle', { fontFamily: 'Geneva' }),
            }
        ],
    },
    {
        type: 'dropdown',
        label: 'Font Size',
        icon: faTextHeight,
        items: [
            // {
            //     label: 'Small',
            //     action: (editor) => editor.chain().focus().setMark('textStyle', { fontSize: '0.8em' }).run(),
            //     isActive: (editor) => editor.isActive('textStyle', { fontSize: '0.8em' }),
            // },
            // {
            //     label: 'Normal',
            //     action: (editor) => editor.chain().focus().setMark('textStyle', { fontSize: '1em' }).run(),
            //     isActive: (editor) => editor.isActive('textStyle', { fontSize: '1em' }),
            // },
            // {
            //     label: 'Large',
            //     action: (editor) => editor.chain().focus().setMark('textStyle', { fontSize: '1.2em' }).run(),
            //     isActive: (editor) => editor.isActive('textStyle', { fontSize: '1.2em' }),
            // },
            // {
            //     label: 'Extra Large',
            //     action: (editor) => editor.chain().focus().setMark('textStyle', { fontSize: '1.4em' }).run(),
            //     isActive: (editor) => editor.isActive('textStyle', { fontSize: '1.4em' }),
            // },
            {
                label: '11px',
                action: (editor) => editor.chain().focus().setMark('textStyle', { fontSize: '11px' }).run(),
                isActive: (editor) => editor.isActive('textStyle', { fontSize: '11px' }),
            },
            {
                label: '12px',
                action: (editor) => editor.chain().focus().setMark('textStyle', { fontSize: '12px' }).run(),
                isActive: (editor) => editor.isActive('textStyle', { fontSize: '12px' }),
            },
            {
                label: '14px',
                action: (editor) => editor.chain().focus().setMark('textStyle', { fontSize: '14px' }).run(),
                isActive: (editor) => editor.isActive('textStyle', { fontSize: '14px' }),
            },
            {
                label: '16px',
                action: (editor) => editor.chain().focus().setMark('textStyle', { fontSize: '16px' }).run(),
                isActive: (editor) => editor.isActive('textStyle', { fontSize: '16px' }),
            },
            {
                label: '18px',
                action: (editor) => editor.chain().focus().setMark('textStyle', { fontSize: '18px' }).run(),
                isActive: (editor) => editor.isActive('textStyle', { fontSize: '18px' }),
            },
            {
                label: '24px',
                action: (editor) => editor.chain().focus().setMark('textStyle', { fontSize: '24px' }).run(),
                isActive: (editor) => editor.isActive('textStyle', { fontSize: '24px' }),
            },
            {
                label: '26px',
                action: (editor) => editor.chain().focus().setMark('textStyle', { fontSize: '26px' }).run(),
                isActive: (editor) => editor.isActive('textStyle', { fontSize: '26px' }),
            },
            {
                label: '28px',
                action: (editor) => editor.chain().focus().setMark('textStyle', { fontSize: '28px' }).run(),
                isActive: (editor) => editor.isActive('textStyle', { fontSize: '28px' }),
            },
        ],
    },
    {
        type: 'button',
        label: 'Bold',
        icon: faBold,
        action: (editor) => editor.chain().focus().toggleBold().run(),
        isActive: (editor) => editor.isActive('bold'),
    },
    {
        type: 'button',
        label: 'Italic',
        icon: faItalic,
        action: (editor) => editor.chain().focus().toggleItalic().run(),
        isActive: (editor) => editor.isActive('italic'),
    },
    {
        type: 'button',
        label: 'Underline',
        icon: faUnderline,
        action: (editor) => editor.chain().focus().toggleUnderline().run(),
        isActive: (editor) => editor.isActive('underline'),
    },
    {
        type: 'button',
        label: 'Strikethrough',
        icon: faStrikethrough,
        action: (editor) => editor.chain().focus().toggleStrike().run(),
        isActive: (editor) => editor.isActive('strike'),
    },
    {
        type: 'button',
        label: 'Superscript',
        icon: faSuperscript,
        action: (editor) => editor.chain().focus().toggleSuperscript().run(),
        isActive: (editor) => editor.isActive('superscript'),
    },
    {
        type: 'button',
        label: 'Subscript',
        icon: faSubscript,
        action: (editor) => editor.chain().focus().toggleSubscript().run(),
        isActive: (editor) => editor.isActive('subscript'),
    },
    {
        type: 'button',
        label: 'Bullet List',
        icon: faListUl,
        action: (editor) => editor.chain().focus().toggleBulletList().run(),
        isActive: (editor) => editor.isActive('bulletList'),
    },
    {
        type: 'button',
        label: 'Ordered List',
        icon: faListOl,
        action: (editor) => editor.chain().focus().toggleOrderedList().run(),
        isActive: (editor) => editor.isActive('orderedList'),
    },
    {
        type: 'button',
        label: 'Task List',
        icon: faCheckSquare,
        action: (editor) => editor.chain().focus().toggleTaskList().run(),
        isActive: (editor) => editor.isActive('taskList'),
    },
    {
        type: 'button',
        label: 'Link',
        icon: faLink,
        action: (editor) => {
            const url = window.prompt('URL');
            if (url) {
                editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run();
            }
        },
        isActive: (editor) => editor.isActive('link'),
    },
    {
        type: 'dropdown',
        label: 'Justify',
        icon: faAlignJustify,
        items: [
            {
                label: 'Align Left',
                icon: faAlignLeft,
                action: (editor) => editor.chain().focus().setTextAlign('left').run(),
                isActive: (editor) => editor.isActive({ textAlign: 'left' }),
            },
            {
                label: 'Align Center',
                icon: faAlignCenter,
                action: (editor) => editor.chain().focus().setTextAlign('center').run(),
                isActive: (editor) => editor.isActive({ textAlign: 'center' }),
            },
            {
                label: 'Align Right',
                icon: faAlignRight,
                action: (editor) => editor.chain().focus().setTextAlign('right').run(),
                isActive: (editor) => editor.isActive({ textAlign: 'right' }),
            },
        ],
    },
    {
        type: 'button',
        label: 'Table',
        icon: faTable,
        action: (editor) => editor.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: true }).run(),
    },
    {
        type: 'dropdown',
        label: 'Insert',
        icon: faPlus,
        items: [
            {
                label: 'Image',
                icon: faImage,
                action: (editor) => {
                    const url = window.prompt('Image URL');
                    if (url) {
                        editor.chain().focus().setImage({ src: url }).run();
                    }
                },
            },
            {
                label: 'YouTube Video',
                icon: faVideo,
                action: (editor) => {
                    const url = window.prompt('YouTube Video URL');
                    if (url) {
                        editor.commands.setYoutubeVideo({ src: url });
                    }
                },
            },
            {
                label: 'Code Block',
                icon: faCode,
                action: (editor) => editor.chain().focus().toggleCodeBlock().run(),
                isActive: (editor) => editor.isActive('codeBlock'),
            },
            {
                label: 'Drawing Canvas',
                icon: faPencilAlt,
                action: (editor) => editor.chain().focus().insertContent({
                    type: 'paper',
                    attrs: { lines: [] }
                }).run(),
            },
            // {
            //     label: 'Details/Summary',
            //     icon: faCaretSquareDown,
            //     action: (editor) => editor.chain().focus().setDetailsSummary().run(),
            //     isActive: (editor) => editor.isActive('detailsSummary'),
            // },
        ],
    },
    {
        type: 'colorPicker',
        label: 'Text Color',
    },
    {
        type: 'button',
        label: 'Highlight',
        icon: faHighlighter,
        action: (editor) => editor.chain().focus().toggleHighlight().run(),
        isActive: (editor) => editor.isActive('highlight'),
    },
];

export const tableSubmenuConfig = [
    {
        label: 'Insert Table',
        icon: faTable,
        action: (editor) => editor.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: true }).run(),
    },
    {
        label: 'Add Column Before',
        icon: faArrowLeft,
        action: (editor) => editor.chain().focus().addColumnBefore().run(),
    },
    {
        label: 'Add Column After',
        icon: faArrowRight,
        action: (editor) => editor.chain().focus().addColumnAfter().run(),
    },
    {
        label: 'Delete Column',
        icon: faMinus,
        action: (editor) => editor.chain().focus().deleteColumn().run(),
    },
    {
        label: 'Add Row Before',
        icon: faArrowUp,
        action: (editor) => editor.chain().focus().addRowBefore().run(),
    },
    {
        label: 'Add Row After',
        icon: faArrowDown,
        action: (editor) => editor.chain().focus().addRowAfter().run(),
    },
    {
        label: 'Delete Row',
        icon: faMinus,
        action: (editor) => editor.chain().focus().deleteRow().run(),
    },
    {
        label: 'Toggle Header Column',
        icon: faTable,
        action: (editor) => editor.chain().focus().toggleHeaderColumn().run(),
    },
    {
        label: 'Toggle Header Row',
        icon: faTable,
        action: (editor) => editor.chain().focus().toggleHeaderRow().run(),
    },
    {
        label: 'Merge Cells',
        icon: faPlus,
        action: (editor) => editor.chain().focus().mergeCells().run(),
    },
    {
        label: 'Split Cell',
        icon: faMinus,
        action: (editor) => editor.chain().focus().splitCell().run(),
    },
    {
        label: 'Delete Table',
        icon: faTrash,
        action: (editor) => editor.chain().focus().deleteTable().run(),
    },
];