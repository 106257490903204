import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import "../../../assets/css/ToolbarDropdown.css"

const ToolbarDropdown = ({ item, editor }) => {
    const [isOpen, setIsOpen] = useState(false);

    // Function to determine if this is the font dropdown
    const isFontDropdown = item.label === 'Font';

    // Function to get inline style for font preview
    const getFontStyle = (fontName) => {
        if (isFontDropdown) {
            return {
                fontFamily: `${fontName}, var(--bs-font-sans-serif)`,
                fontSize: '14px',
                lineHeight: '1.5'
            };
        }
        return {};
    };

    return (
        <div className="btn-group me-2 mb-2" role="group">
            <button
                type="button"
                className={`btn btn-sm btn-outline-secondary dropdown-toggle border-0 ${isOpen ? 'show' : ''}`}
                onClick={() => setIsOpen(!isOpen)}
                aria-expanded={isOpen}
                aria-haspopup="true"
                aria-label={`${item.label} options`}
                style={isFontDropdown && editor?.getAttributes('textStyle').fontFamily ?
                    getFontStyle(editor.getAttributes('textStyle').fontFamily) : {}}
            >
                {item.icon && <FontAwesomeIcon icon={item.icon} className="me-2" />}
                {isFontDropdown && editor?.getAttributes('textStyle').fontFamily ?
                    editor.getAttributes('textStyle').fontFamily : item.label}
            </button>
            <ul
                className={`dropdown-menu ${isOpen ? 'show' : ''}`}
                aria-label={`${item.label} options`}
                style={isFontDropdown ? { minWidth: '200px' } : {}}
            >
                {item.items.map((subItem, index) => (
                    <li key={index}>
                        <button
                            className={`dropdown-item ${subItem.isActive && subItem.isActive(editor) ? 'active' : ''}`}
                            onClick={() => {
                                subItem.action(editor);
                                setIsOpen(false);
                            }}
                            aria-label={subItem.label}
                            style={getFontStyle(subItem.label)}
                        >
                            {subItem.icon && <FontAwesomeIcon icon={subItem.icon} className="me-2" />}
                            <span>{subItem.label}</span>
                        </button>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default ToolbarDropdown;