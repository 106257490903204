// import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ToolbarButton = ({ item, editor }) => {
    return (
        <button
            type="button"
            onClick={() => item.action(editor)}
            className={`btn btn-sm btn-outline-secondary me-2 mb-2 border-0 ${item.isActive && item.isActive(editor) ? 'active' : ''}`}
            aria-label={item.label}
            aria-pressed={item.isActive && item.isActive(editor)}
        >
            <FontAwesomeIcon icon={item.icon} />
        </button>
    );
};

export default ToolbarButton;
