import { Extension } from '@tiptap/core'
import { Plugin, PluginKey } from 'prosemirror-state'

export const TrailingNode = Extension.create({
    name: 'trailingNode',

    addOptions() {
        return {
            node: 'paragraph',
            notAfter: ['paragraph'],
        }
    },

    addProseMirrorPlugins() {
        const plugin = new Plugin({
            key: new PluginKey('trailingNode'),
            appendTransaction: (transactions, oldState, newState) => {
                const { doc, tr } = newState
                const { node: nodeType, notAfter } = this.options
                const shouldInsertNodeAtEnd = !notAfter.includes(doc.lastChild?.type.name)

                if (shouldInsertNodeAtEnd && doc.content.size > 0) {
                    const node = newState.schema.nodes[nodeType].createAndFill()
                    if (node) {
                        tr.insert(doc.content.size, node)
                        return tr
                    }
                }
                return null
            },
        })

        return [plugin]
    },
})