// // CodeBlockWithLanguageSelect.js
// import React, { useCallback } from 'react';
// import { NodeViewWrapper, NodeViewContent } from '@tiptap/react';

// const CodeBlockWithLanguageSelect = ({ node, updateAttributes, editor }) => {
//     const handleKeyDown = useCallback((event) => {
//         if (event.key === 'Enter') {
//             event.preventDefault();
//             editor.commands.insertContent('\n');
//         } else if (event.key === 'Tab') {
//             event.preventDefault();
//             editor.commands.insertContent('  '); // Insert two spaces for indentation
//         }
//     }, [editor]);

//     return (
//         <NodeViewWrapper className="code-block-wrapper">
//             <select
//                 contentEditable={false}
//                 defaultValue={node.attrs.language}
//                 onChange={event => updateAttributes({ language: event.target.value })}
//             >
//                 <option value="javascript">JavaScript</option>
//                 <option value="python">Python</option>
//                 {/* Add more language options as needed */}
//             </select>
//             <pre>
//                 {/* <NodeViewContent as="code" onKeyDown={handleKeyDown} /> */}
//                 <NodeViewContent as="code" />
//             </pre>
//         </NodeViewWrapper>
//     );
// };

// export default CodeBlockWithLanguageSelect;

// CodeBlockWithLanguageSelect.js
// CodeBlockWithLanguageSelect.js
import { useCallback, memo } from 'react';
import { NodeViewWrapper, NodeViewContent } from '@tiptap/react';

const CodeBlockWithLanguageSelect = ({ node, updateAttributes, editor }) => {
    const handleKeyDown = useCallback(
        (event) => {
            if (event.key === 'Enter') {
                event.preventDefault();
                editor.commands.insertText('\n');
            } else if (event.key === 'Tab') {
                event.preventDefault();
                editor.commands.insertText('  '); // Insert two spaces for indentation
            }
        },
        [editor]
    );

    return (
        <NodeViewWrapper className="code-block-wrapper">
            <select
                contentEditable={false}
                value={node.attrs.language || ''}
                onChange={(event) => updateAttributes({ language: event.target.value })}
            >
                <option value="">Select language</option>
                <option value="javascript">JavaScript</option>
                <option value="python">Python</option>
                {/* Add more language options as needed */}
            </select>
            <pre>
                <NodeViewContent as="code" onKeyDown={handleKeyDown} />
            </pre>
        </NodeViewWrapper>
    );
};

export default memo(
    CodeBlockWithLanguageSelect,
    (prevProps, nextProps) => prevProps.node.attrs.language === nextProps.node.attrs.language
);


