// import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight, faAngleDoubleLeft, faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons";
import { format } from 'date-fns';
import '../../assets/css/CalendarHeader.css';

const CalendarHeader = ({
    currentDate,
    selectedDate,
    prevMonth,
    nextMonth,
    goToPreviousDate,
    goToNextDate
}) => {
    const formattedMonth = format(currentDate, "MMMM yyyy");
    const formattedDay = format(selectedDate, "d");

    return (
        <div className="d-flex justify-content-between align-items-center calendar-header-font">
            <button
                className="btn btn-sm btn-outline-secondary border-0 calendar-header-btn"
                onClick={prevMonth}
                aria-label="Previous month"
            >
                <FontAwesomeIcon icon={faAngleDoubleLeft} />
            </button>
            <button
                className="btn btn-sm btn-outline-secondary border-0 calendar-header-btn"
                onClick={goToPreviousDate}
                aria-label="Previous date"
            >
                <FontAwesomeIcon icon={faChevronLeft} />
            </button>
            <h5 className="mb-0 fs-5 text-center date-display">
                <span className="selected-day">{formattedDay}</span> {formattedMonth}
            </h5>
            <button
                className="btn btn-sm btn-outline-secondary border-0 calendar-header-btn"
                onClick={goToNextDate}
                aria-label="Next date"
            >
                <FontAwesomeIcon icon={faChevronRight} />
            </button>
            <button
                className="btn btn-sm btn-outline-secondary border-0 calendar-header-btn"
                onClick={nextMonth}
                aria-label="Next month"
            >
                <FontAwesomeIcon icon={faAngleDoubleRight} />
            </button>
        </div>
    );
};

export default CalendarHeader;