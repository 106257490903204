// import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const ModalMenu = ({ isOpen, onClose, title, menuItems, onItemClick }) => {
    if (!isOpen) return null;

    return (
        <div className="modal d-block" tabIndex="-1" role="dialog">
            <div className="modal-dialog modal-dialog-scrollable" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{title}</h5>
                        <button type="button" className="btn-close" onClick={onClose} aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <ul className="list-group">
                            {menuItems.map((item, index) => (
                                <li key={index} className="list-group-item">
                                    <button
                                        className="btn btn-link text-start w-100"
                                        onClick={() => {
                                            onItemClick(item);
                                            onClose();
                                        }}
                                    >
                                        <FontAwesomeIcon icon={item.icon} className="me-2" />
                                        <span>{item.label}</span>
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalMenu;