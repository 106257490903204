// useTiptapConfig.jsx
import { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { useEditor } from '@tiptap/react';
import { createExtensions } from './editorExtensions';
import { useAuth } from '../../contexts/AuthContext';

export const useTiptapConfig = (initialContent, onUpdate, selectedDate) => {
    const [currentColor, setCurrentColor] = useState('#000000');
    const { user } = useAuth();
    const prevInitialContentRef = useRef();
    const isUpdatingRef = useRef(false);

    const handleUpdate = useCallback(
        ({ editor }) => {
            if (!isUpdatingRef.current) {
                const json = editor.getJSON();
                onUpdate(json);
            }
        },
        [onUpdate]
    );

    const editorExtensions = useMemo(() => createExtensions(user?.username), [user?.username]);

    const editorConfig = useMemo(() => ({
        extensions: editorExtensions,
        content: initialContent,
        onUpdate: handleUpdate,
        editorProps: {
            attributes: {
                username: user?.username || 'anonymous',
            },
        },
    }), [editorExtensions, initialContent, handleUpdate, user?.username]);

    const editor = useEditor(editorConfig);

    useEffect(() => {
        if (editor) {
            const color = editor.getAttributes('textStyle').color || '#000000';
            setCurrentColor(color);
        }
    }, [editor]);

    useEffect(() => {
        if (editor && initialContent) {
            const currentContent = JSON.stringify(editor.getJSON());
            const newContent = JSON.stringify(initialContent);
            if (currentContent !== newContent && JSON.stringify(prevInitialContentRef.current) !== newContent) {
                isUpdatingRef.current = true;
                editor.commands.setContent(initialContent, false, { preserveWhitespace: 'full' });
                prevInitialContentRef.current = initialContent;
                isUpdatingRef.current = false;
            }
        }
    }, [editor, initialContent, selectedDate]);

    useEffect(() => {
        return () => {
            if (editor) {
                editor.destroy();
            }
        };
    }, [editor]);

    return editor;
};