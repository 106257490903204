import { useState, useCallback, forwardRef, useImperativeHandle } from 'react';
import { format, subDays, isToday, parseISO } from 'date-fns';
import { useAuth } from '../../contexts/AuthContext';
import UndoneTasksModal from './UndoneTasksModal';

const STORAGE_KEY = 'undoneTasksChecked';

const UndoneTasksManager = forwardRef(({ apiService, selectedDate, onTasksMoved, setError, updateEntry }, ref) => {
    const [showModal, setShowModal] = useState(false);
    const [scanningDates, setScanningDates] = useState([]);
    const [isScanning, setIsScanning] = useState(false);
    const [currentRange, setCurrentRange] = useState(0);
    const { user } = useAuth();

    const SCAN_RANGES = [
        { days: 1, label: 'yesterday' },
        { days: 7, label: 'last week' },
        { days: 30, label: 'last month' }
    ];

    const cleanupStorage = useCallback(() => {
        try {
            const storage = localStorage.getItem(STORAGE_KEY);
            if (storage) {
                const checks = JSON.parse(storage);
                // Keep only today's checks and clean up old entries
                const cleanedChecks = Object.entries(checks).reduce((acc, [username, dateStr]) => {
                    if (isToday(parseISO(dateStr))) {
                        acc[username] = dateStr;
                    }
                    return acc;
                }, {});
                localStorage.setItem(STORAGE_KEY, JSON.stringify(cleanedChecks));
            }
        } catch (error) {
            console.error('Error cleaning up storage:', error);
            // If there's an error, clear the storage completely
            localStorage.removeItem(STORAGE_KEY);
        }
    }, []);

    const hasCheckedToday = useCallback(() => {
        try {
            const storage = localStorage.getItem(STORAGE_KEY);
            if (!storage) return false;

            const checks = JSON.parse(storage);
            const lastCheck = checks[user?.username];

            return lastCheck && isToday(parseISO(lastCheck));
        } catch (error) {
            console.error('Error checking storage:', error);
            return false;
        }
    }, [user?.username]);

    const markCheckedToday = useCallback(() => {
        try {
            const storage = localStorage.getItem(STORAGE_KEY);
            const checks = storage ? JSON.parse(storage) : {};

            checks[user?.username] = format(new Date(), 'yyyy-MM-dd');
            localStorage.setItem(STORAGE_KEY, JSON.stringify(checks));

            // Clean up old entries
            cleanupStorage();
        } catch (error) {
            console.error('Error updating storage:', error);
        }
    }, [user?.username, cleanupStorage]);

    const separateAndMoveUndoneTasks = (content) => {
        let movedContent = [];

        const processNode = (node) => {
            if (Array.isArray(node)) {
                return node.map(processNode).filter(Boolean);
            }

            if (node.type === 'taskList') {
                const doneTasks = [];
                const undoneTasks = [];
                node.content.forEach(item => {
                    if (item.type === 'taskItem') {
                        if (item.attrs.checked) {
                            doneTasks.push(item);
                        } else {
                            undoneTasks.push(item);
                        }
                    } else {
                        doneTasks.push(item);
                    }
                });
                if (undoneTasks.length > 0) {
                    movedContent.push({ type: 'taskList', content: undoneTasks });
                }
                return doneTasks.length > 0 ? { ...node, content: doneTasks } : null;
            }

            if (node.type === 'detailsSummary') {
                const { done, undone } = separateTasks(node.content);
                if (undone.length > 0) {
                    movedContent.push({
                        type: 'detailsSummary',
                        attrs: { ...node.attrs },
                        content: undone
                    });
                }
                return done.length > 0 ? { ...node, content: done } : null;
            }

            if (node.content) {
                const processedContent = processNode(node.content);
                if (processedContent === null || processedContent.length === 0) {
                    return null;
                }
                return { ...node, content: processedContent };
            }

            return node;
        };

        const updatedContent = processNode(content);
        return { updatedContent, movedContent };
    };

    const separateTasks = (content) => {
        let done = [];
        let undone = [];

        const processTasks = (nodes) => {
            nodes.forEach(node => {
                if (node.type === 'taskList') {
                    const { done: doneTasks, undone: undoneTasks } = separateTasks(node.content);
                    if (doneTasks.length > 0) {
                        done.push({ ...node, content: doneTasks });
                    }
                    if (undoneTasks.length > 0) {
                        undone.push({ ...node, content: undoneTasks });
                    }
                } else if (node.type === 'taskItem') {
                    if (node.attrs.checked) {
                        done.push(node);
                    } else {
                        undone.push(node);
                    }
                } else if (node.content) {
                    const { done: doneContent, undone: undoneContent } = separateTasks(node.content);
                    if (doneContent.length > 0) {
                        done.push({ ...node, content: doneContent });
                    }
                    if (undoneContent.length > 0) {
                        undone.push({ ...node, content: undoneContent });
                    }
                } else {
                    done.push(node);
                }
            });
        };

        processTasks(Array.isArray(content) ? content : [content]);
        return { done, undone };
    };

    const addTasksToContent = (content, movedContent) => {
        if (movedContent.length === 0) return content;

        const updatedContent = { ...content };

        if (!Array.isArray(updatedContent.content)) {
            updatedContent.content = [];
        }

        // Check if we need to add a paragraph before tasks
        const needsParagraph = updatedContent.content.length > 0 &&
            !updatedContent.content[updatedContent.content.length - 1].type?.includes('paragraph');

        // If content is not empty and doesn't end with a paragraph, add one paragraph
        if (needsParagraph) {
            updatedContent.content.push({ type: 'paragraph' });
        }

        updatedContent.content = [...updatedContent.content, ...movedContent];
        return updatedContent;
    };

    const scanRange = useCallback(async (date, rangeIndex) => {
        const range = SCAN_RANGES[rangeIndex];
        const startDate = format(subDays(date, range.days), 'yyyy-MM-dd');
        const endDate = format(subDays(date, 1), 'yyyy-MM-dd');

        try {
            const results = await apiService.scanUndoneEntries(
                user.username,
                startDate,
                endDate
            );

            if (results && results.length > 0) {
                setScanningDates(results.map(r => r.date));
                setShowModal(true);
                setIsScanning(false);
                return true;
            }
            return false;
        } catch (error) {
            console.error(`Error scanning ${range.label}:`, error);
            return false;
        }
    }, [apiService, user?.username]);

    const moveUndoneTasks = async () => {
        if (!apiService || !user?.username || !scanningDates.length) return;

        try {
            const todayDate = format(selectedDate, 'yyyy-MM-dd');
            const todayEntry = await apiService.getEntry(user.username, todayDate);
            let todayContent = todayEntry?.content ?
                (typeof todayEntry.content === 'string' ? JSON.parse(todayEntry.content) : todayEntry.content)
                : { type: 'doc', content: [] };

            // Process each date with undone tasks
            for (const date of scanningDates) {
                const sourceEntry = await apiService.getEntry(user.username, date);
                if (!sourceEntry?.content) continue;

                const sourceContent = typeof sourceEntry.content === 'string' ?
                    JSON.parse(sourceEntry.content) : sourceEntry.content;

                // Use the original task processing logic
                const { updatedContent, movedContent } = separateAndMoveUndoneTasks(sourceContent);

                // Update source entry with remaining tasks
                await apiService.addEntry(
                    user.username,
                    date,
                    JSON.stringify(updatedContent)
                );

                // Update local UI for source entry if needed
                if (updateEntry) {
                    updateEntry(date, updatedContent);
                }

                // Add moved tasks to today's content
                todayContent = addTasksToContent(todayContent, movedContent);
            }

            // Save today's content
            await apiService.addEntry(
                user.username,
                todayDate,
                JSON.stringify(todayContent)
            );

            // Update local UI for today's entry
            if (updateEntry) {
                updateEntry(todayDate, todayContent);
            }

            // Mark as checked in local storage
            markCheckedToday();

            // Notify parent component of the update
            onTasksMoved(todayContent);

            // Close modal and clean up state
            setShowModal(false);
            setScanningDates([]);

        } catch (error) {
            console.error("Failed to move undone tasks:", error);
            setError(`Failed to move undone tasks: ${error.message}`);
            setShowModal(false);
            setScanningDates([]);
        }
    };

    const scanForUndoneTasks = useCallback(async (date) => {
        if (!apiService || !user?.username) return;

        // Check settings from local storage
        try {
            const settingsStr = localStorage.getItem('journalSettings');
            if (settingsStr) {
                const settings = JSON.parse(settingsStr);
                if (!settings.enableUncompletedTasks) {
                    console.log('Undone tasks feature is disabled in settings');
                    return;
                }
            } else {
                // If no settings found, don't proceed
                // console.log('No journal settings found');
                return;
            }
        } catch (error) {
            console.error('Error reading journal settings:', error);
            return;
        }

        // Check if we've already scanned today
        if (hasCheckedToday()) {
            // console.log('Already checked for undone tasks today');
            return;
        }

        setIsScanning(true);
        setShowModal(true);
        setCurrentRange(0);

        const scanNextRange = async (rangeIndex) => {
            if (rangeIndex >= SCAN_RANGES.length) {
                setIsScanning(false);
                setShowModal(false);
                // Mark as checked even if no tasks were found
                markCheckedToday();
                return;
            }

            const foundTasks = await scanRange(date, rangeIndex);
            if (!foundTasks) {
                setTimeout(() => {
                    setCurrentRange(rangeIndex + 1);
                    scanNextRange(rangeIndex + 1);
                }, 100);
            } else {
                // Mark as checked when tasks are found
                markCheckedToday();
            }
        };

        scanNextRange(0);
    }, [apiService, user?.username, scanRange, hasCheckedToday, markCheckedToday]);

    useImperativeHandle(ref, () => ({
        checkForUndoneTasks: scanForUndoneTasks
    }));

    return (
        <UndoneTasksModal
            show={showModal}
            onHide={() => {
                if (!isScanning) {
                    setShowModal(false);
                    setScanningDates([]);
                    markCheckedToday(); // Mark as checked when user dismisses modal
                }
            }}
            onMove={moveUndoneTasks}
            isScanning={isScanning}
            currentRange={SCAN_RANGES[currentRange]?.label}
            dateRange={scanningDates.length > 0 ? {
                start: scanningDates[0],
                end: scanningDates[scanningDates.length - 1]
            } : null}
        />
    );
});

export default UndoneTasksManager;