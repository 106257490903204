// editorExtensions.jsx
import { forwardRef } from 'react';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import Link from '@tiptap/extension-link';
import TextAlign from '@tiptap/extension-text-align';
import Table from '@tiptap/extension-table';
import TableRow from '@tiptap/extension-table-row';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import CodeBlockLowlight from '@tiptap/extension-code-block-lowlight';
import { common, createLowlight } from 'lowlight'
import Placeholder from '@tiptap/extension-placeholder';
import Typography from '@tiptap/extension-typography';
import TaskList from '@tiptap/extension-task-list';
import TaskItem from '@tiptap/extension-task-item';
import Color from '@tiptap/extension-color';
import TextStyle from '@tiptap/extension-text-style';
import Youtube from '@tiptap/extension-youtube';
import { Extension } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';
import CustomImage from './CustomImage';
import { ImageSelection } from './ImageSelectionExtension';
import CodeBlockWithLanguageSelect from './CodeBlockWithLanguageSelect';
import Superscript from '@tiptap/extension-superscript'
import Subscript from '@tiptap/extension-subscript'
// import { DetailsSummary } from './DetailsSummaryExtension'
import { TrailingNode } from './TrailingNodeExtension';
import Highlight from '@tiptap/extension-highlight';
import FontFamily from '@tiptap/extension-font-family';
import { Paper } from './Paper';

const lowlight = createLowlight(common)


const CustomCodeBlockLowlight = CodeBlockLowlight.extend({
    addKeyboardShortcuts() {
        return {
            ...this.parent?.(),
            Enter: ({ editor }) => {
                if (editor.isActive('codeBlock')) {
                    editor.commands.insertContent('\n');
                    return true;
                }
                return false;
            },
            Tab: ({ editor }) => {
                if (editor.isActive('codeBlock')) {
                    editor.commands.insertContent('  '); // Insert two spaces for indentation
                    return true;
                }
                return false;
            },
        };
    },
    addNodeView() {
        return ReactNodeViewRenderer(CodeBlockWithLanguageSelect);
    },
    addAttributes() {
        return {
            ...this.parent?.(),
            language: {
                default: null,
                parseHTML: element => element.getAttribute('language'),
                renderHTML: attributes => {
                    if (!attributes.language) {
                        return {};
                    }
                    return { language: attributes.language };
                },
            },
        };
    },
});

const CustomCommands = Extension.create({
    name: 'customCommands',
    addCommands() {
        return {
            setImageSize: (image, width) => ({ tr, dispatch }) => {
                const pos = tr.doc.resolve(image.getAttribute('data-node-id'));
                if (dispatch) {
                    tr.setNodeMarkup(pos.before(), null, {
                        ...pos.nodeAfter.attrs,
                        width: `${width}px`,
                        height: 'auto',
                    });
                }
                return true;
            },
        };
    },
});

const CustomTextStyle = TextStyle.extend({
    addAttributes() {
        return {
            ...this.parent?.(),
            fontSize: {
                default: null,
                parseHTML: element => element.style.fontSize,
                renderHTML: attributes => {
                    if (!attributes.fontSize) {
                        return {}
                    }
                    return {
                        style: `font-size: ${attributes.fontSize}`,
                    }
                },
            },
        }
    },
});


export const createExtensions = (username) => [
    StarterKit.configure({
        codeBlock: false,
        paragraph: {
            HTMLAttributes: {
                class: 'indentable',
            },
        },
    }),
    Underline,
    Link.configure({
        openOnClick: false,
    }),
    CustomImage.configure({
        customImageOptions: {
            username: username || 'anonymous',
            onImageResize: (attrs, width, height) => {
                // Handle image resize if needed
                console.log('Image resized:', { attrs, width, height });
            }
        },
    }),
    TextAlign.configure({
        types: ['heading', 'paragraph'],
        alignments: ['left', 'center', 'right'],
        defaultAlignment: 'left',
    }),
    Table.configure({
        resizable: true,
        lastColumnResizable: true,
        cellMinWidth: 100,
    }),
    TableHeader,
    TableRow,
    TableCell,
    CustomCodeBlockLowlight.configure({
        lowlight,
        defaultLanguage: 'javascript',
        HTMLAttributes: {
            class: 'code-block-wrapper',
        },
    }),
    Placeholder.configure({
        placeholder: 'Write something...',
    }),
    Typography,
    TaskList,
    TaskItem.configure({
        nested: true,
    }),
    CustomTextStyle,
    Color,
    Youtube.configure({
        controls: true,
        nocookie: true,
        progressBarColor: 'white',
        modestBranding: true,
        renderHTML: ({ node }) => {
            return [
                'div',
                { class: 'youtube-wrapper' },
                [
                    'iframe',
                    {
                        src: node.attrs.src,
                        frameborder: 0,
                        allowfullscreen: 'true',
                        allow:
                            'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture',
                        width: '100%',
                        height: '100%',
                    },
                ],
            ];
        },
    }),
    TrailingNode.configure({
        node: 'paragraph',
        notAfter: ['TaskList', 'paragraph', 'heading', 'blockquote', 'horizontalRule', 'detailsSummary'],
    }),
    Highlight.configure({
        multicolor: false,
        HTMLAttributes: {
            class: 'highlight-yellow',
        },
    }),
    FontFamily.configure({
        types: ['textStyle'],
    }),
    CustomCommands,
    ImageSelection,
    Superscript,
    Subscript,
    Paper,
    // DetailsSummary,
    // CustomKeymap,
];

export default createExtensions;