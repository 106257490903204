import { useRef } from 'react';

const ToolbarColorPicker = ({ editor, currentColor, setCurrentColor }) => {
    const colorPickerRef = useRef(null);

    const handleColorChange = (e) => {
        const newColor = e.target.value;
        setCurrentColor(newColor);
        editor.chain().focus().setColor(newColor).run();
    };

    const handleColorPickerClick = () => {
        colorPickerRef.current.click();
    };

    return (
        <div className="btn-group me-2 mb-2" role="group">
            <button
                type="button"
                className="btn btn-sm btn-outline-secondary border-0"
                onClick={handleColorPickerClick}
                aria-label="Choose text color"
            >
                A
                <div
                    className="d-inline-block ms-1"
                    style={{
                        width: '1em',
                        height: '1em',
                        backgroundColor: currentColor,
                        verticalAlign: 'middle'
                    }}
                ></div>
            </button>
            <input
                ref={colorPickerRef}
                type="color"
                value={currentColor}
                onChange={handleColorChange}
                aria-label="Color picker"
                style={{
                    position: 'absolute',
                    width: '1px',
                    height: '1px',
                    padding: 0,
                    margin: '-1px',
                    overflow: 'hidden',
                    clip: 'rect(0, 0, 0, 0)',
                    whiteSpace: 'nowrap',
                    border: 0
                }}
            />
        </div>
    );
};

export default ToolbarColorPicker;
