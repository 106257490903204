// import React from "react";

const CustomAlert = ({ onSave, onDiscard, show }) => {
  if (!show) return null;

  return (
    <div className="alert alert-warning" role="alert">
      <strong>Unsaved changes!</strong> Would you like to save before changing the
      date?
      <button className="btn btn-outline-primary btn-sm ms-2" onClick={onSave}>
        Save
      </button>
      <button
        className="btn btn-outline-secondary btn-sm ms-2"
        onClick={onDiscard}
      >
        Discard
      </button>
    </div>
  );
};

export default CustomAlert;
